<app-date-controls (dateChange)="getDateChange($event)"></app-date-controls>
<div class="tabs is-toggle is-fullwidth is-small my-3">
  <ul>
      <li *ngFor="let tab of tabs; let i = index" data-tab="tab-{{i}}"
          [ngClass]="{'is-active': current == i}"
          (click)="clickTab(i, tab)">
        <a>{{ tab }}</a>
      </li>
  </ul>
</div>
<app-image-columns [data]="imageArray" [column]="2"></app-image-columns>
