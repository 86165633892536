<div class="columns date-control is-marginless ">
  <div class="column" style="display: flex; align-content: center">
    <div class="buttons has-addons is-flex is-justify-content-end is-flex-wrap-nowrap" style="width: 100%">
      <button class="button" *ngFor="let value of timeChangeAmounts(true)" (click)="changeDateByButton(value)">
        {{ value }}
      </button>
    </div>
  </div>
  <div class="column" style="display: flex; align-items: center;">
    <app-calendar
      [(startDate)]="date"
      [maxDate]="'today'"
      (ready)="changeDate($event)"
      (event)="changeDate($event)"
    ></app-calendar>
  </div>
  <div class="column" style="display: flex; align-items: center">
    <div class="buttons is-flex is-justify-content-start is-flex-wrap-nowrap" style="width: 100%">
      <button class="button" *ngFor="let value of timeChangeAmounts()" (click)="changeDateByButton(value)" [disabled]="isDisableMoveToFuture()">
        {{ value }}
      </button>
    </div>
  </div>
</div>

