import { Component, OnInit } from '@angular/core';
import {PostService} from "../http/post.service";
import {Post} from "../store/schema";

class Pageable {
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
