import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MonitorComponent} from "./monitor.component";
import {RouterModule} from "@angular/router";
import { ModelingMonitorComponent } from './modeling-monitor/modeling-monitor.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [MonitorComponent, ModelingMonitorComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [MonitorComponent]
})
export class MonitorModule { }
