<div class="field is-grouped is-vcentered">
    <p class="control" *ngFor="let controls of controlGroup; let i=index">
        <app-select [controls]="controls" (indexChange)="changeSelect($event)"></app-select>
    </p>
    <div class="tabs is-small is-toggle mx-3">
        <ul>
          <li class=""><a [routerLink]="['/routine-data-collected/sensing-data/pandora']">View image</a></li>
        </ul>
    </div>
</div>
<app-resources-table [postSelectControls]="controlGroup"  [boardTypes]="types"></app-resources-table>
