<app-time-range-slider [currentDate]="currentDate" [unit]="'m'" [relativeMin]="585" [relativeMax]="1046"
  [intervals]="[60]" [interval]="60" [step2]="60" (ready)="readyDate($event)" (valueChange)="changeDateValue($event)"
  (dateChange)="changeCurrentDate($event)">
</app-time-range-slider>
<div class="tabs is-toggle is-fullwidth is-small my-3">
  <ul>
    <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabName of tabNames">
      <a [routerLink]="[ '/routine-data-collected', 'satellite-data', 'gems', (tabName | lowercase)]">
        <span>{{ tabName }}</span>
      </a>
    </li>
  </ul>
</div>
<app-image-columns
  [column]="3"
  [data]="imageSchema"
></app-image-columns>
