<section class="hero is-fullheight">
    <nav class="navbar is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
                [ngClass]="{'is-active': isExpanded}" (click)="toggle()">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div id="navbarBasic" class="navbar-menu" [ngClass]="{'is-active': isExpanded}">
        <div class="navbar-end">
            <div class="navbar-item has-text-right">
                <ng-container *ngIf="!auth.hasAuthentication(); else elseTemplate">
                    <div class="buttons are-small is-right">
                        <a class="button is-info" href="http://localhost:4200/">
                            Other Campaign
                        </a>
                        <a class="button is-warning" [routerLink]="[ '/signin' ]">
                            Sign in
                        </a>
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    <div class="buttons are-small is-right">
                        <a class="button is-info" href="https://www.sijaq.org">
                            Other Campaign
                        </a>
                        <a class="button is-light" [routerLink]="[ '/profile']">
                            Profile
                        </a>
                        <a class="button is-success has-text-weight-bold" (click)="signout()">
                            Sign out
                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    </nav>
    <div class="columns">
        <div class="column is-1"></div>
        <div class="column is-10">
            <h1 class="title menu-item">AIRCRAFT-OTHERS</h1>
            <router-outlet></router-outlet>
        </div>
        <div class="column is-1"></div>
    </div>
    <footer class="footer ">
        <div class="columns">
            <!-- <div class="column is-2 ml-3 mr-3">
                <img src="https://via.placeholder.com/400x200?text=footer+logo" />
            </div> -->
            <div>
                <div class="is-size-7">
                    Address : 1001-3, 4th Engineering Center, 50, UNIST-gil, Eonyang-eup, Ulju-gun, Ulsan, Republic of Korea
                </div>
                <div class="columns is-size-7 is-gapless">
                    <div class="mr-3">
                        Postal code : 44919
                    </div>
                    <div class="mr-3">
                        <i class="fas fa-phone fa-sm"></i>: 052-217-2835
                    </div>
                    <div class="mr-3">
                        <i class="fas fa-fax"></i>: 052-217-2836
                    </div>
                    <div class="mr-3">
                        <i class="fas fa-mail-bulk"></i> : cksong@unist.ac.kr
                    </div>
                </div>
                <div class="is-size-7">
                    Copyright (C) <span class="has-text-weight-bold">SIJAQ.</span> All rights reserved
                </div>
            </div>
        </div>
    </footer>
</section>
