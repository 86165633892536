<div [formGroup]="form">
<!--    <div class="control">-->
<!--        <label class="is-family-primary has-text-weight-bold mr-3">{{ label || (controlName | titlecase) }}</label>-->
<!--        <label class="radio" *ngFor="let value of values ; let i = index">-->
<!--            <input type="radio" class="mr-1" [name]="controlName" [value]="value" [formControlName]="controlName" [attr.disabled]="disabled"-->
<!--            required>-->
<!--            <span *ngIf="images"><img [src]="images[i]" class="mr-2"></span>-->
<!--            <span>{{ subLabels[i] || (value | titlecase) }}</span>-->
<!--        </label>-->
<!--    </div>-->
  <div class="control is-flex is-align-content-center is-justify-content-start">
    <div class="tag is-active is-hidden-mobile" style="height: auto">{{ label || (controlName | titlecase) }}</div>
    <div class="select is-flex-grow-3 is-fullwidth">
      <select [formControlName]="controlName" [attr.disabled]="disabled">
        <option *ngFor="let value of values; let i = index" [value]="value">{{ subLabels[i] || (value | titlecase) }}</option>
      </select>
    </div>
  </div>
</div>
