import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { TableModule } from 'src/app/common/table/table.module';
import { GemsComponent } from './gems.component';
import {DateControlsModule} from "../../../../../../common/calendar/date-controls/date-controls.module";
import {ImageColumnsModule} from "../../../../../../common/image-columns/image-columns.module";

@NgModule({
  declarations: [GemsComponent],
    imports: [
        CommonModule,
        RouterModule,
        TimeRangeSliderModule,
        TableModule,
        DefaultImageModule,
        DateControlsModule,
        ImageColumnsModule
    ],
  providers: [
    DatePipe
  ]
})
export class GemsModule { }
