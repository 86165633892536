
<app-date-controls [date]="currentDate" (dateChange)="onDateChanged($event)"></app-date-controls>
<div class="tabs is-toggle is-fullwidth is-small my-3">
  <ul>
    <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabName of tabNames">
      <a [routerLink]="[ '/routine-data-collected', 'satellite-data', 'tropomi', (tabName | lowercase)]">
        <span>{{ tabName }}</span>
      </a>
    </li>
  </ul>
</div>

<div class="content">
  <h3>NRT</h3>
  <div class="columns">
    <div class="column" *ngFor="let header of imageHeaders; let i = index">
      <div class="card is-rounded">
        <header class="card-header hero is-info is-bold">
          <p class="card-header-title has-text-white">
            {{ header.name }}
          </p>
        </header>
        <div class="card-image">
          <figure class="mx-5 my-5">
            <img [src]="nrtImageSources[i]" />
          </figure>
        </div>
      </div>
    </div>
  </div>

  <h3>OFFL</h3>
  <div class="columns">
    <div class="column" *ngFor="let header of imageHeaders; let i = index">
      <div class="card">
        <header class="card-header hero is-info is-bold">
          <p class="card-header-title has-text-white">
            {{ header.name }}
          </p>
        </header>
        <div class="card-image">
          <figure class="mx-5 my-5">
            <img [src]="offlImageSources[i]" />
          </figure>
        </div>
      </div>
    </div>
  </div>

</div>
