import { Component, OnInit } from '@angular/core';
import { ModelInfo, MODELS_INFO } from '../[doc]/models';

@Component({
  selector: 'app-m-comparison',
  templateUrl: './comparison.component.html'
})
export class ComparisonComponent implements OnInit {

  constructor() { }

  modelInfo:ModelInfo[] = MODELS_INFO;

  ngOnInit(): void {
  }

}
