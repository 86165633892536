import {ContentChild, Directive, Input, OnChanges, SimpleChanges, TemplateRef} from "@angular/core";

@Directive()
export class AbstractTable implements OnChanges {

    @ContentChild('row', { static: false })
    rowTemplateRef: TemplateRef<any>;

    @Input()
    data: any[];

    @Input()
    size: number = 10;

    @Input()
    fillEmptyRows: boolean = true;

    get emptyRows() {
      if (!this.fillEmptyRows) return [];
      return [...Array(this.size - (this.data?.length || 0))]
    }

    private _headers: string[] = null;
    @Input()
    set headers(hs) {
        this._headers = hs;
    }
    get headers() {
      return typeof(this.data) != "undefined"
      && typeof(this.data[0]) != "undefined"
        ? this._headers || Object.keys(this.data[0])
        : this._headers;
    }

    public headerToCssClass(header: string) {
      return {['col_' + header.toLowerCase().replace(/\s/g, '-')] : true};
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.data) {
          this.data = changes.data.currentValue;
      }
      if (changes.size) {
          this.size = changes.size.currentValue;
      }
      if (changes.headers) {
          this.headers = changes.headers.currentValue;
      }
      if (changes.fillEmptyRows) {
          this.fillEmptyRows = changes.fillEmptyRows.currentValue;
      }
    }

}
