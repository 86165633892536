<app-date-controls [date]="currentDate" (dateChange)="onDateChanged($event)"></app-date-controls>

<div class="card my-3">
    <header class="card-header hero is-info is-bold">
      <p class="card-header-title has-text-white">
            Time Series of Reflectivity (VIS) and TB (IR)
        </p>
    </header>

    <div class="card-content">
        <div *ngFor="let src of sources ; let i = index">
            <article class="message is-white" *ngIf="!hasError(i)">
                <div class="message-header">
                    #{{ i + 1 }}
                </div>
                <div class="message-body">
                    <figure class="mx-3 my-3">
                        <img [src]="src" (error)="onOccuredError(i)" />
                    </figure>
                </div>
            </article>
        </div>

        <div *ngIf="isAllNotAvailable()">
            <img [src]="alterImage">
        </div>
    </div>
</div>
