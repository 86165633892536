import { Directive, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FileType } from "./file-type.enum";

@Directive()
export class AbstractEditor implements OnInit {
    @Input()
    id: string;

    @Input()
    title: string = "";

    @Input()
    content: string = "";

    @Input()
    type!: number;

    constructor(protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit(): void {
        this.id = this.router.url.split("/").pop();
      console.log('this.id: ', this.id)
    }

    navigateToBack() {
        const urlSegments = this.router.url.split("/");
        urlSegments.pop();
        const previousUrl = urlSegments.join("/");
        this.router.navigateByUrl(previousUrl);
    }

    extensionToIconClass(filename: string): string {
        return FileType.fileNameToIconClass(filename);
    }
}
