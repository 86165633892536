import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef, ViewEncapsulation
} from '@angular/core';
import { AbstractTable } from '../abstract-table';
import {trigger, state, style, animate, transition,} from '@angular/animations';

@Component({
  selector: 'app-collapsible-table',
  templateUrl: './collapsible-table.component.html',
  styleUrls: ['./collapsible-table.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expansion', [
      state('collapsed', style({
        height: 0,
        opacity: 0,
      })),
      state('expanded', style({
        height: '500px',
        opacity: 1,
        padding: '20px',
        marginBottom: '10px',
        overflow: 'hidden',
      })),
      transition('collapsed => expanded', [
        animate('1s')
      ]),
      transition('expanded => collapsed', [
        animate('1s')
      ]),
    ])
  ],
})
export class CollapsibleTableComponent extends AbstractTable {

  @ContentChild('expanded', { static: false }) expandedTemplate: TemplateRef<any>;
  @Input() expandedIndex = null;
  @Output() open = new EventEmitter<number>();
  ngOnInit(): void {
    if (this.open.observers.length <= 0)
      this.open = null;
  }
  onOpen(e: Event, id: number) {
    e.stopPropagation();
    e.preventDefault();
    this.open?.emit(id);
  }
}
