import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "lodash";
import { SelectService } from 'src/app/common/select/select.service';
import { AuthService } from 'src/app/http/auth.service';
import { PostService } from 'src/app/http/post.service';
import {Control} from "../../../../../common/highcharts/highcharts.component";

@Component({
  selector: 'app-pandora-list',
  templateUrl: './pandora-list.component.html'
})
export class PandoraListComponent implements OnInit {

  types: number[] = [1];
  controlGroup: Control<number>[][] = [];

  constructor(public post: PostService, public router: Router, private route: ActivatedRoute, public auth: AuthService, public select: SelectService) { }

  ngOnInit(): void {
    const data = this.route.snapshot.data;
    this.controlGroup = this.select.typeToControls(data['type']);
  }

  changeSelect(index: number) {
    const clone = _.cloneDeep(this.types);
    clone[0] = index + 1;
    this.types = clone;
  }
}
