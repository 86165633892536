import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectService } from 'src/app/common/select/select.service';
import { AuthService } from 'src/app/http/auth.service';
import { PostService } from 'src/app/http/post.service';
import { File, Post } from 'src/app/store/schema';
import { StoreService } from 'src/app/store/store.service';
import { RichTextViewerComponent } from '../rich-text-viewer.component';

@Component({
  selector: 'app-enhanced-rich-text-viewer',
  templateUrl: './enhanced-rich-text-viewer.component.html',
  styleUrls: ['./enhanced-rich-text-viewer.component.css']
})
export class EnhancedRichTextViewerComponent extends RichTextViewerComponent implements OnInit, OnDestroy {

  data: Post;
  protected storeKey: string;
  protected subscription: Subscription;

  constructor(protected route: ActivatedRoute, protected router: Router, protected auth: AuthService, protected post: PostService, protected store: StoreService, public select: SelectService) {
    super(route, router, auth, select);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.id = this.route.snapshot.url.pop().path;
    this.storeKey = this.route.snapshot.data.storeKey?? this.router.url.split("/")[2];

    this.subscription = this.post.read(this.storeKey, this.id).subscribe(data => {
      this.data = data;
      this.store.publish(this.storeKey, data);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  downloadFile(file: File) {
    this.post.downloadFile(this.storeKey, this.id, file);
  }

  get parentUrl(): string[] {
    return this.route.snapshot.pathFromRoot
      .filter(snapshot => snapshot.url.length > 0)
      .map(snapshot => snapshot.url[0].path).slice(0, 2);
  }

  requestDeletePost(id: string) {
    this.post.delete(this.storeKey, id).subscribe(_ => {
      this.router.navigate(this.parentUrl);
    }, err => {
      alert(err);
    });
  }

  navigateToEditPage(id) {
    this.router.navigate(this.router.url.split("/", 3).concat("edit", id), {
      relativeTo: this.route,
      queryParams: {editor: this.data.editor},
    });
  }
}
