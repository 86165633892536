import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dateFnsFp from "date-fns/fp";
import { map } from 'rxjs/operators';
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { ModelInfo } from '../../[doc]/models';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  currentDate: Date = new Date();
  sliderDate: Date = new Date();
  verticalCurtainDate!: Date;

  form: FormGroup;

  @Input()
  modelInfo: ModelInfo[];

  @Input()
  domain;

  @Input()
  areas;

  domainOrSection: string;
  domainOrSection_folder: string;
  area: string;

  constructor(private fb: FormBuilder, public validator: ValidatorService, private changeRef: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.domainOrSection = [this.domain[0],"SURFACE"].join("_");
    this.domainOrSection_folder = this.domain[0];
    this.area = this.areas[0];

    this.form = this.fb.group({
      kind: ['spatial', [Validators.required]],
      domain: [this.domain[0], [Validators.required]],
      area: [this.areas[0]],
      variable: ['PM25', [Validators.required]],
      section: ['CROSS01', [Validators.required]]
    });

    this.form.valueChanges.pipe(
      map((value) => {
        let section = value.section;

        if(value.kind.trim() == 'spatial'){
          this.domainOrSection_folder = value.domain;
          this.domainOrSection = [value.domain,"SURFACE"].join("_");
          this.area = value.area;

        }else{

          this.domainOrSection_folder = section.startsWith('CROSS') ? 'cross_section' : 'curtain';
          this.domainOrSection = value.section.trim();
          this.area = '';

        }
        return value;
      })
    ).subscribe();
  }

  isSelectedVertical() {
    return this.form.value.kind == 'vertical';
  }

  isSelectedCurtain(){
    return this.isSelectedVertical() && this.form.value.section.toLowerCase().includes('curtain');
  }


  getReady(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
  //  this.makeImageData();
  }

  getDateChange(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
  //  this.makeImageData();
  }

  getValueChange(date: Date) {
    this.sliderDate = date;
  //  this.makeImageData();
  }

  makeImageData() {
    let imageArray = [];

    let kind =this.form.value.kind;
    let variable = this.form.value.variable;

    let ymdFolderPath = dateFnsFp.format("yyyy/MM/dd")(this.currentDate);
    const fileDate = this.isSelectedCurtain() ? this.verticalCurtainDate : this.sliderDate;

    //FIXME 파일명 고치자고 하면 고칠 것
    let area = this.area == this.areas[1] ? 'PK' : this.areas[0];

    for (const info of Object.values(this.modelInfo)) {
      const dirs = ["/image", ymdFolderPath, "modeling", info.name.toLowerCase(), kind, this.domainOrSection_folder].join("/") + (this.domainOrSection_folder == this.domain[2] ? "/"+ area.toLowerCase() :  '');

      const fileName = info.team
                        + (info.team == (null || "") ? "" : "_")
                        + info.name
                        + "_"
                        + variable
                        + "_"
                        + this.domainOrSection
                        + "_"
                        + (this.domainOrSection_folder == this.domain[2] ? area.toUpperCase() + "_" : '')
                        + dateFnsFp.format("yyyyMMddHH")(fileDate)+".png";

      imageArray.push({
        title: info.name,
        src:  dirs + "/" +fileName
      })
    }

    return imageArray;
  }

}
