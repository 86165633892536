import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {FlightPlanComponent} from "./flight-plan.component";
import {TableModule} from "../../../common/table/table.module";
import {ResourcesTableModule} from "../../../common/table/resources-table/resources-table.module";

@NgModule({
  declarations: [FlightPlanComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ResourcesTableModule,
  ],
  exports: [FlightPlanComponent]
})
export class FlightPlanModule { }
