<app-time-range-slider
  [unit]="'h'"
  [intervals]="[sliderInterval]"
  [interval]="sliderInterval"
  [step2]="sliderInterval == 3 ? 3 : 3"
  [step2Interval]="sliderInterval == 3 ? 2 : 1"
  [currentDate]="currentDate"
  (ready)="handleOnReady($event)"
  (dateChange)="handleDateChange($event)"
  (valueChange)="handleValueChange($event)"
  [relativeMin]="24"
  [relativeMax]="97"
  [speed]="sliderSpeed"
></app-time-range-slider>

<div class="my-3"></div>
<div class="columns is-vcentered is-mobile">
  <div class="column">
    <div class="radio-field-container">
      <app-radio-field
        [values]="kinds"
        [subLabels]="kindLabels"
        [label]="'kind'"
        [(form)]="form"
        [controlName]="'kind'"
        (change)="columnCount"
      ></app-radio-field>
    </div>
  </div>

  <div class="column">
    <div class="radio-field-container">
      <app-radio-field
        [values]="domains"
        [subLabels]="domainLabels"
        [label]="'domain'"
        [(form)]="form"
        [controlName]="'domain'"
        (change)="columnCount"
      ></app-radio-field>
    </div>
  </div>

  <div class="column">
    <div class="radio-field-container">
      <app-radio-field
        [values]="variables"
        [subLabels]="variableLabels"
        [label]="'variable'"
        [(form)]="form"
        [controlName]="'variable'"
      ></app-radio-field>
    </div>
  </div>
</div>

<app-image-columns
  [data]="makeImageData(columnCount)"
  [column]="columnCount"
  [hasDownload]="showDownloadButton"
></app-image-columns>
