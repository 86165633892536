<table class="table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Steps</th>
      <th>Time</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of results">
      <td>{{result.date}}</td>
      <td>{{result.steps}}</td>
      <td>{{result.time}}</td>
    </tr>
  </tbody>
</table>

<input type="date" name="date" (ngModelChange)="onChangeDate($event)" [(ngModel)]="date" class="form-control" required>
<select name="steps" (ngModelChange)="onChangeSteps($event)" [(ngModel)]="step" [disabled]="!date" class="form-control" required>
  <option *ngFor="let step of steps" [value]="step.value">{{step.label}}</option>
</select>
<button type="submit" class="btn btn-primary" [disabled]="!date || !step" (click)="onClickStartButton()">Start</button>
<button type="button" class="btn btn-danger" [disabled]="!date" (click)="onClickStopButton()">Stop</button>
