import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { AuthService } from 'src/app/http/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  emailErrorMessage: string;
  loginErrorMessage: string;
  invalidPasswordMessage = "Must be at least 8 characters, including letters (case sensitive), numbers, and at least 1 special character.";

  constructor(private fb: FormBuilder,
              public auth: AuthService,
              public validator: ValidatorService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private _location: Location
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required]], //this.validator.emailValidator()
      password: ['', [Validators.required, this.validator.passwordValidator()]]
    });
  }

  submit() {
    const value = this.form.value;
    this.auth.login(value).subscribe(_response => {
      // let url;
      // this.activeRoute.queryParams.forEach(r => url = r?.prop);
      // if(_response != null)
      //   this.router.navigate([url || "/"]);
      this._location.back();
    }, (error: HttpErrorResponse) => {
      if(error.status == 401 || error.status == 405) {
        this.loginErrorMessage = "Invalid user. Please sign up or Wait for your approval.";
      }
  });
  }
}
