import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {$e} from "codelyzer/angular/styles/chars";

interface Menu {
  path: string
  title?: string
  children?: Menu[]
}

@Component({
  selector: 'app-content-with-sidebar',
  templateUrl: './content-with-sidebar.component.html',
  styleUrls: ['./content-with-sidebar.component.scss']
})
export class ContentWithSidebarComponent {
  private subscription: Subscription;

  subTitle1: string = "";
  subTitle2: string = "";
  isExpanded: boolean = true;

  rootUrl: string = "";
  menus: Menu[] = [];

  constructor(router: Router, route: ActivatedRoute) {
    this.subscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(_ => {
      const urlSegments = router.url.split("/");
      this.rootUrl = urlSegments[1];
      this.subTitle1 = urlSegments[2]?.replace(/_/gi, " ");
      this.subTitle2 = urlSegments[3] ? urlSegments[3].split("?")[0] : null;

      const childrenOfRoot = route.snapshot.routeConfig.children;
      const targetRouteConfig = childrenOfRoot?.filter(c => c.path == urlSegments[1])[0];

      if (targetRouteConfig.children == undefined)
        return [];

        this.menus = targetRouteConfig?.children
        .filter(p => p.path.match(/^[a-z0-9_-]+$/i) != null)
        .map(p => {
          if (typeof (p.children) == "undefined") {
            return {
              path: p.path,
              title: p.data?.title
            }
          }
          else {
            return {
              path: p.path,
              title: p.data?.title,
              children: p.children.filter(c => !c.data?.hiddenSubMenu && c.path.match(/^[a-z0-9_-]+$/i) != null).map(c => ({ path: c.path, title: c.data?.title }))
            }
          }
        });
    });
  }
  handleOnClickExpansion() {
      this.isExpanded = !this.isExpanded;
  }

  get adjusetdRootUrl() {
    return this.adjustUrl(this.rootUrl);
  }

  adjustUrl(url: string) {
    return url.replace(/_and_/gi, " & ").replace(/_/gi, " ").toUpperCase();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected readonly $e = $e;
}
