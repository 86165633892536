<div class="table-container">
    <table class="table is-fullwidth" style="border: 1px solid #dbdbdb;">
        <thead>
            <tr style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb;">
                <th class="has-text-centered" *ngFor="let header of headers"
                    [ngClass]="headerToCssClass(header)">
                  {{ header }}
                </th>
            </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let element of data; let i=index">
              <tr [ngClass]="{'row': open != null}" (click)="onOpen($event, element.id)">
                  <ng-container *ngIf="rowTemplateRef" [ngTemplateOutlet]="rowTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:element, index:i}">
                  </ng-container>
              </tr>
              <tr [@expansion]="expandedIndex == i ? 'expanded' : 'collapsed'"
                  [ngClass]="{'is-hidden': expandedIndex != i}" class="collapsible-body">
                  <td [colSpan]="headers.length" class="collapsible-body">
                      <div>
                          <ng-container *ngIf="expandedTemplate"
                              [ngTemplateOutlet]="expandedTemplate"
                              [ngTemplateOutletContext]="{$implicit:element}"
                          ></ng-container>
                      </div>
                  </td>
              </tr>
          </ng-container>
          <tr *ngFor="let _ of emptyRows let i=index" class="row">
            <td *ngFor="let header of headers" class="has-text-centered"></td>
          </tr>
        </tbody>
    </table>
</div>
