import { ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-aeronet-china',
  templateUrl: './aeronet-china.component.html',
  styleUrls: ['./aeronet-china.component.css']
})
export class AeronetChinaComponent {

  tabs: string[] = [
    "Beijing-CAMS",
    "Beijing_RADI"
  ];

  current:any = 0;
  currentName:any = this.tabs[0];
  selectedDate: Date = new Date();

  rootUrl = "/finep/";
  imageArray!:any[];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  clickTab(tabid?: number, tabname?:string) {
    this.current = tabid;
    this.currentName = tabname;
    this.makeImageArray();
  }

  titles:string[] = [
    "Daily AOD (UTC)", "AOD Monthly Timeseries (UTC)",
    "Fine Mode AOD & Coarse Mode AOD (UTC)", "FMA&CMA AOD Monthly Timeseries (UTC)",
    "당일 Fine Mode Fraction (UTC)", "FMF Monthly Timeseries (UTC)"
  ];

  filenames:string[] = [
    "aod", "fc-aod", "fmf"
  ]

  makeImageArray(): any{
    let dateFormat = [dateFns.format(this.selectedDate, "yyyy"), dateFns.format(this.selectedDate, "yyyyMM")].join("/");

    this.imageArray = [];
    let num = -1;

    this.filenames.map((keyword:string, index:number) => {
      num++;

      this.imageArray?.push({
          title: this.titles[index+num],
          src: this.rootUrl+["aeronet", dateFormat, dateFns.format(this.selectedDate, "yyyyMMdd"), [this.currentName, keyword, dateFns.format(this.selectedDate, "yyyy-MM-dd") + ".gif"].join("-")].join("/")
      });
      this.imageArray?.push({
          title: this.titles[index+(num+1)],
          src: this.rootUrl+["aeronet", dateFormat, [this.currentName, keyword, dateFns.format(this.selectedDate, "yyyy-MM") + ".gif"].join("-")].join("/")
      });
    });

    //console.log(this.imageArray);
  }

  getDateChange(date: Date){
    this.selectedDate = date;
    this.makeImageArray();
  }





}
