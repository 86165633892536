<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (SFC)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('wsfc')"></app-image-table> -->
<app-image-columns [data]="getImageSources('wsfc')" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (850hPa)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('wt85')"></app-image-table> -->
<app-image-columns [data]="getImageSources('wt85')" [column]="2"></app-image-columns>

<!--FIXME 나중에 기술원에서 파일 받으면 고칠것 -->

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (SFC-preci.)</p>
<app-image-columns
  [data]="getApiImageSchema(
  '',
  'https://www.weather.go.kr/w/repositary/image/cht/img/kim_gdps_lc40_fxko4r1_'+today+'00.png'
  )"
  [column]="1"
></app-image-columns>
<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (850hPa)</p>
<app-image-columns
  [data]="getApiImageSchema(
  '',
  'https://www.weather.go.kr/w/repositary/image/cht/img/kim_gdps_lc20_fxko78_'+today+'00.png'
  )"
  [column]="1"
></app-image-columns>
