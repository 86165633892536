import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  errorCode: string
  content: string

  contentMap = {
    '404': 'Page not found',
    '500': 'Internal server error, please contact support',
    '공사중': '페이지는 아직 준비중입니다.'
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    switch (this.router.url) {
      case '/404':
        this.errorCode = '404'
        break
      case '/500':
        this.errorCode = '500'
        break
      case '/announcement':
        this.errorCode = '공사중'
        break
      default:
        this.errorCode = '404'
    }
    this.content = this.contentMap[this.errorCode] || this.contentMap[404]
  }
}
