import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { File, Post } from '../store/schema';
import { ApiGateway } from './api-gateway';


type FileType = 'geojson' | 'csv'

@Injectable({
  providedIn: 'root'
})
export class FileService extends ApiGateway {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get apiUrl(): string {
    return "api";
  }

  downloadFile(subPath: string, filename: string, params?: string | { [key: string]: string }) {
    super.download(subPath, filename, params).subscribe();
  }

}
