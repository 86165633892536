import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as dateFnsFp from "date-fns/fp";
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import * as _ from "lodash";
import {Subject} from "rxjs";
import {ImageSchema} from "../../../../../../../common/image-columns/image-columns.component";

type Kind = 'spatial' | 'flight' | 'tagged' | 'ground';
type FlightDomain = 'dc8' | 'nara' | 'c90gt' | '1900d-yellowsea' | '1900d-sma';
type SpatialDomain = '27km' | '09km';
type Domain = SpatialDomain | FlightDomain | 'korea-univ';

type SliderOptions = {
  [key in Kind]?: {
    label: string,
    children: {
      [key in Domain]?: {
        label: string,
        interval: number,
        speed: number,
        disableSlider?: boolean,
        variables: string[],
      }
    }
  }
}

interface Option {
  id: number,
  label: string,
  value: string,
  children?: Option['id'][],
}

@Component({
  selector: 'app-ensemble-east-asia',
  templateUrl: './east-asia.component.html',
  styleUrls: ['./east-asia.component.css']
})
export class EastAsiaComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  kinds = [];
  domains = [];
  variables = [];
  currentDate: Date = new Date();
  sliderDate: Date = new Date();
  sliderInterval: number;
  sliderSpeed: number = 1;
  column: number = 1;
  showDownloadButton: boolean = false;

  sliderOptions: SliderOptions = {
    'spatial': {
      label: 'Spatial',
      children: {
        '27km': {
          label: '27km',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4-CO2']
        },
        '09km': {
          label: '09km',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO']
        },
      }
    },
    'flight': {
      label: 'Flight',
      children: { // DC8, NARA, C90GT, 1900D
        'dc8': {
          label: 'DC-8',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4', 'CO2']
        },
        'nara': {
          label: 'NARA',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4', 'CO2']
        },
        'c90gt': {
          label: 'C90GT',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4', 'CO2']
        },
        '1900d-yellowsea': {
          label: '1900D (Yellow Sea)',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4', 'CO2']
        },
        '1900d-sma': {
          label: '1900D (SMA)',
          interval: 1,
          speed: 0.66666,
          variables: ['PM25', 'O3', 'NH3', 'CO', 'NO2', 'SO2', 'HCHO', 'CH4', 'CO2']
        },
      },
    },
    'tagged': {
      label: 'Tagged',
      children: {
        '27km': {
          label: '27km',
          interval: 1,
          speed: 0.66666,
          variables: ['CO']
        }
      }
    },
    'ground': {
      label: 'Ground',
      children: {
        'korea-univ': {
          label: 'Korea University',
          interval: 1,
          speed: 0.66666,
          disableSlider: true,
          variables: ['ALL']
        }
      },
    }
  }

  get kind() {
    return this.form.value.kind;
  }
  getKinds() {
    return Object.keys(this.sliderOptions);
  }
  get kindLabels() {
    return Object.keys(this.sliderOptions).map((key) => (this.sliderOptions[key].label || key));
  }
  get domain() {
    return this.form.value.domain;
  }
  getDomains(kind) {
    return Object.keys(this.sliderOptions[kind].children)
  }
  get domainLabels() {
    return Object.keys(this.sliderOptions[this.kind].children).map(
      (key) => (this.sliderOptions[this.kind].children[key].label || key)
    );
  }
  getInterval(kind, domain) {
    return this.sliderOptions[kind].children[domain]?.interval || 1;
  }
  get variable() {
    return this.form.value.variable;
  }
  getVariables(kind, domain) {
    return this.sliderOptions[kind].children[domain]?.variables || [];
  }
  get variableLabels() {
    return this.sliderOptions[this.kind].children[this.domain]?.variables || [];
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public validator: ValidatorService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.changeRef.markForCheck();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      kind: ['spatial', [Validators.required]],
      domain: ['27km', [Validators.required]],
      variable: ['PM25', [Validators.required]],
      interval: [1, [Validators.required]],
      speed: [1, [Validators.required]],
    });
    this.subscribeChanges();
    this.kinds = this.getKinds();
    this.domains = this.getDomains(this.kinds[0]);
    this.variables = this.getVariables(this.kinds[0], this.domains[0]);
    this.sliderInterval = this.getInterval(this.kinds[0], this.domains[0]);
    this.sliderSpeed = this.sliderOptions[this.kinds[0]].children[this.domains[0]]?.speed || 1;
  }

  subscribeChanges() {
    this.form.get('interval').valueChanges.subscribe((interval) => {
      this.sliderInterval = interval;
    })
    this.form.get('domain').valueChanges.subscribe((domain) => {
      this.form.get('interval').setValue(this.getInterval(this.kind, domain));
      this.sliderInterval = this.getInterval(this.kind, domain);
      this.form.get('variable').setValue(this.getVariables(this.kind, domain)[0]);
      this.variables = this.getVariables(this.kind, domain);
      this.form.get('speed').setValue(this.sliderOptions[this.kind].children[domain]?.speed || 1);
      this.sliderSpeed = this.sliderOptions[this.kind].children[domain]?.speed || 1;
      this.showDownloadButton = this.kind === 'ground' && domain === 'korea-univ';
    });
    this.form.get('kind').valueChanges.subscribe((kind) => {
      this.form.get('domain').setValue(this.getDomains(kind)[0]);
      this.domains = this.getDomains(kind);
      this.showDownloadButton = this.kind === 'ground' && this.domain === 'korea-univ';
    });
  }

  handleOnReady(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
  }

  handleDateChange(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
  }

  handleValueChange(date: Date) {
    this.sliderDate = date;
  }

  // handleOnClickDownload([path: string, filename: string]) {
  //   const imageArray = this.makeImageData(this.column);
  //   const imageArraySubject = new Subject<ImageSchema[]>();
  //   imageArraySubject.subscribe((imageArray) => {
  //     this.downloadImages(imageArray);
  //   });
  //   imageArraySubject.next(imageArray);
  //   imageArraySubject.complete();
  // }

  get columnCount() {
    return 1
  }

  makeImageData(columnCount) {
    let type = 'ensemble'
    let kind = this.form.value.kind;
    let domain = this.form.value.domain;
    let variable = this.form.value.variable;
    const modelDate = this.currentDate;
    const forecastDate = dateFnsFp.addDays(1)(modelDate);

    if (variable.toUpperCase() === 'CH4-CO2') type = 'SNUGC'
    if (variable.toUpperCase() === 'CH4' || variable.toUpperCase() === 'CO2') type = 'SNUGC'

    let imageArray: ImageSchema[] = [];

    let title = 'Ensemble';
    if (kind === 'tagged') title = 'GEOS-Chem (Tagged)';
    else if (type === 'SNUGC') title = 'GEOS-Chem';

    let imageFilenamePattern = '{type}_{kind}_{domain}_{variable}_{date}_{offset}.png'
    if (kind === 'ground') imageFilenamePattern = '{type}_{kind}_{domain}_{variable}_{date}00.png'
    for (let i = 0; i < columnCount; i++) {
      const dir = [
        "/image",
        modelDate.getFullYear(),
        String(modelDate.getMonth() + 1).padStart(2, '0'),
        String(modelDate.getDate()).padStart(2, '0'),
        "modeling",
        type.toLowerCase(),
        kind.toLowerCase(),
        domain.toLowerCase(),
        variable?.toLowerCase()
      ].join("/")
      const filename = imageFilenamePattern
        .replace('{type}', _.capitalize(type))
        .replace('{kind}', kind.toLowerCase())
        .replace('{domain}', domain.toLowerCase())
        .replace('{variable}', variable?.toUpperCase())
        .replace('{date}', dateFnsFp.format("yyyyMMdd")(forecastDate))
        .replace('{offset}', String(dateFnsFp.differenceInHours(forecastDate)(this.sliderDate)).padStart(3, '0'))

      const imageSchema : ImageSchema = { title: title, src: dir + "/" + filename }
      if (!this.showDownloadButton) { imageArray.push(imageSchema); continue; }
      const refDownloadUrlParams = {
        type: type.toLowerCase(),
        kind: kind.toLowerCase(),
        domain: domain.toLowerCase(),
        variable: variable?.toLowerCase(),
        modelDate: dateFnsFp.format("yyyyMMdd")(modelDate),
        forecastDate: dateFnsFp.format("yyyyMMdd")(forecastDate),
      }
      // not for actual path matching, just for downloaded filename
      const refFilename = '{type}_{kind}_{domain}_{variable}_{date}00.csv'
        .replace('{type}', _.capitalize(type))
        .replace('{kind}', kind.toLowerCase())
        .replace('{type}', _.capitalize(type))
        .replace('{kind}', kind.toLowerCase())
        .replace('{domain}', domain.toLowerCase())
        .replace('{variable}', variable?.toUpperCase())
        .replace('{date}', dateFnsFp.format("yyyyMMdd")(forecastDate))
      imageSchema.ref = {
        filename: refFilename,
        path: "v1/file/csv",
        params: refDownloadUrlParams
      }
      imageArray.push(imageSchema);
    }
    return imageArray;
  }
}
