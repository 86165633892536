<app-check-remove-post-modal [(active)]="isShowModalForDeletePost" (remove)="deletePost()">
</app-check-remove-post-modal>

<div class="mb-4">
<!--      <div class="field has-text-right mb-4">-->
<!--        <span class="mr-3 tag">-->
<!--            <i class="far fa-eye"></i>-->
<!--            view:-->
<!--            {{ view }}-->
<!--        </span>-->
<!--        <span class="mr-3">-->
<!--            <i class="far fa-clock"></i>-->
<!--            {{ updatedAt | date: 'yyyy-MM-dd HH:mm:ss':'+1800' }}-->
<!--        </span>-->
<!--        <span class="mr-3">-->
<!--            <i class="fas fa-user-edit"></i>-->
<!--            {{ editor }}-->
<!--        </span>-->

    <div class="field is-grouped is-flex is-grouped-multiline is-justify-content-flex-end mb-4 is-fullwidth">
        <div class="control">
            <div class="tags has-addons">
                <span class="tag ">
<!--                    <i class="far fa-eye"></i><span class="m-1"></span>-->
                    view
                </span>
                <span class="tag is-white">{{ view }}</span>
            </div>
        </div>
        <div class="control">
          <div class="tags has-addons">
            <span class="tag ">editor</span>
            <span class="tag is-white">
  <!--                    <i class="fas fa-user-edit"></i><span class="m-1"></span>-->
              {{ editor }}
                  </span>
          </div>
        </div>
        <div class="control">
          <div class="tags has-addons">
            <span class="tag ">created</span>
            <span class="tag is-white">
    <!--                    <i class="far fa-clock"></i><span class="m-1"></span>-->
              {{ createdAt | date: 'yyyy-MM-dd HH:mm:ss':'+1800' }}
                    </span>
          </div>
        </div>
        <div class="control">
            <div class="tags has-addons">
                <span class="tag ">updated</span>
                <span class="tag is-white">
<!--                    <i class="far fa-clock"></i><span class="m-1"></span>-->
                    {{ updatedAt | date: 'yyyy-MM-dd HH:mm:ss':'+1800' }}
                </span>
            </div>
        </div>
    </div>

    <div>
        <div class="field has-addons">
            <p class="control is-expanded">
                <span class="is-size-4 has-text-weight-bold">{{ title }}</span>
                <span class="ml-3 is-size-7">{{ getTypes() }}</span>
            </p>
            <ng-content select=[control]></ng-content>
        </div>
    </div>
    <hr>

    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery" *ngIf="files?.length > 0 && gallery"></ngx-gallery>

    <div class="summernote editor-view" *ngIf="content?.length > 0">
        <div [ngxSummernoteView]="content"></div>
    </div>
</div>

<div class="menu mt-5 mb-3" *ngIf="files?.length > 0">
    <p class="menu-label">
        <span>Files</span>
        <span> - {{ files.length }} ({{ totalUploadedFileSize | fileSize }})</span>
    </p>
    <ul class="menu-list">
        <li *ngFor="let file of files">
            <a (click)="clickFile(file)">
                <div class="columns is-mobile is-vcentered">
                    <div class="column">
                        <i class="far" [ngClass]="extensionToIconClass(file.name)"></i>
                        <span class=" ml-1">{{ file.name }}</span>
                    </div>
                    <div class="column is-2 has-text-right">
                        {{ file.size | fileSize }}
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div class="buttons is-right">
    <button class="button" (click)="navigateToBack()">
        Back
    </button>
    <ng-container *ngIf="hasAuthorization()">
        <button type="submit" class="button" (click)="checkDeletePost()">
            Delete
        </button>
        <button type="submit" class="button" (click)="editPost()">
            Edit
        </button>
    </ng-container>
</div>
