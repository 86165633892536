import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersModule } from './members/members.module';
import { RouterModule } from '@angular/router';
import {MonitorModule} from "./monitor/monitor.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MembersModule,
    MonitorModule
  ],
})
export class AdminModule { }
