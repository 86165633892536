import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from 'src/app/http/auth.service';
import {PostService} from 'src/app/http/post.service';
import {Post} from 'src/app/store/schema';
import {SelectService} from '../../select/select.service';
import {File} from '../../../store/schema';
import {
  ChartData,
  Control,
  DateFormatsControl,
  XAxisType,
  NumberFormat
} from '../../highcharts/highcharts.component';
import {HighchartsService} from "../../highcharts/highcharts.service";

type ExpandableContentsType = "chart" | "post";
type ExpandableContents = {
  shouldExpand: ExpandableContentsType,
  isLoaded: boolean,
  file?: File,
  title?: string,
  chartData?: ChartData,
  postContent?: Post['content'],
  controls?: {
    chartHeader?: Control<number>
    xAxis?: Control<string>
    xAxisType?: Control<XAxisType>[],
    xAxisFormat?: DateFormatsControl[] | Control<NumberFormat>[],
  },
  message?: string,
}
type PostViewMode = 'link' | 'expand';
type Column = {
  name: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-resources-table',
  templateUrl: './resources-table.component.html',
  styleUrls: ['./resources-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ResourcesTableComponent implements AfterViewInit, OnChanges {

  @Input() isReadOnly;
  @Input() boardTypes: number[];
  @Input() postSelectControls: Control<number>[][];
  @Input() postViewMode: PostViewMode = 'link';
  @Input() expandableContentsMaxLength: number = 300;

  postData: Post[];
  maxPage = 0;
  currentPage = 1;
  pageSize = 10;
  expandedPostId: number;
  searchKey: any;
  category: 'title' | 'content' = 'title';
  onChangeCategory(category: any) {
    this.category = category;
  }

  onEnterSearchBar(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onPageChange({current: 1});
    }
  }

  get tableHeaders() {
    const headers = this.auth.hasAuthentication()
      ? ["No", "title", "files", "writer", "date", "view"]
      : ["No", "title", "files", "date", "view"];
    return this.boardTypes == null ? headers : [ 'Type' ].concat(headers);
  }

  selectedFileMap: {[key: number] : File[]} = {}; // key = postId
  expandableContentsMap: {[key: number]: ExpandableContents} = {}; // key = postId

  private storeKey: string; // ex) max-doas, car-doas, car-mobile-doas
  subscription: Subscription;

  constructor(public post: PostService,
              public hcs: HighchartsService,
              public router: Router,
              public route: ActivatedRoute,
              public auth: AuthService,
              public select: SelectService,
              private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.storeKey = this.route.snapshot.data.storeKey ?? this.router.url.split("/")[2];
    (async () => {
      await this.loadAllPost();
    })();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.storeKey = this.route.snapshot.data.storeKey ?? this.router.url.split("/")[2];
    if (changes.boardTypes) {
      this.resetChartMap();
    }
  }

  onClickPost(id) {
    if (this.postViewMode === 'expand') {
      this.onTogglePostContents(Number(id))
      return;
    }
    this.router.navigate(['view', id], { relativeTo: this.route });
  }

  onChangeSearchBar(searchKey) {
    this.searchKey = searchKey;
  }

  onTogglePostContents(postId: number) {
    // toggle off
    if (this.expandedPostId === postId) {
      this.expandedPostId = null;
      return;
    }
    // toggle on
    this.expandedPostId = postId;
    this.expandableContentsMap[postId] = {
      shouldExpand: "post",
      postContent: this.getPost(postId)?.content,
      isLoaded: true,
    };
  }

  // 현재는 무조껀 1개의 파일만 선택 가능하도록 되어있음
  async onToggleChart(e: Event, postId: number, files: File[]) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.selectedFileMap[postId] || this.selectedFileMap[postId].length === 0) {
      alert("Select a file.");
      return;
    }

    if (this.selectedFileMap[postId]?.length > 1) {
      alert("Select only one file.");
      return;
    }

    this.expandedPostId = postId;
    if (this.expandableContentsMap[postId]?.shouldExpand === 'chart' && this.expandableContentsMap[postId]?.file === files[0]) {
      console.log("already expanded")
      return;
    }

    // toggle on
    this.expandableContentsMap = await this.post.readChart(this.storeKey, postId, files[0]).toPromise().then(
      (res) => {
        let expandableContentsMap = {}
        expandableContentsMap[postId] = {
          file: files[0],
          shouldExpand: "chart",
          chartData: res,
          title: files[0].name.split(".")[0],
        };
        return expandableContentsMap;
      },
      (error) => {
        let expandableContentsMap = {}
        expandableContentsMap[postId] = {
          file: files[0],
          shouldExpand: "chart",
          title: files[0].name.split(".")[0],
          chartData: null,
          message: error.error.message
        };
        return expandableContentsMap;
      }
    );
  }

  onPageChange(pageInfo) {
    this.currentPage = pageInfo.current;
    window.scrollTo(0, 0);
    this.loadAllPost();
  }

  onClickToggleOffButton($event: MouseEvent, postId) {
    $event.stopPropagation();
    this.expandedPostId = null;
  }
  onMouseOverSearchButton($event: MouseEvent) {
    // diable pointer events
    $event.stopPropagation();
    $event.preventDefault();
    $event.target && (($event.target as HTMLElement).style.pointerEvents = 'none');
  }
  onClickSearchButton($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    console.log("search button clicked")
    this.onPageChange({current: 1});
  }

  onSelectFile(e: Event, postId: number) {
    e.stopPropagation();
    e.preventDefault();
    const fileId = Number((e.target as HTMLSelectElement).value)

    // default option
    if (fileId === -1) {
      delete this.selectedFileMap[postId];
      return;
    }

    // ALL option
    if (fileId === -2) {
      this.selectedFileMap[postId] = this.postData.find(p => Number(p.id) === postId)?.files;
      return;
    }

    // single option
    const file = this.postData
      .find(p => Number(p.id) === postId)?.files
      .find(f => f.id === fileId);
    file
      ? this.selectedFileMap[postId] = [file]
      : delete this.selectedFileMap[postId];
  }

  shouldExpand(postId: number) {
    return this.expandableContentsMap[postId]?.shouldExpand;
  }
  getSelectedFiles(postId: number) {
    return this.selectedFileMap[postId];
  }
  getIndex(postId: number) {
    return this.postData?.findIndex(p => Number(p.id) === postId);
  }
  getTypes(selectedType: number){
    let types = this.select.decode(selectedType);
    return this.postSelectControls
      ?.map((arr, index) => arr[types[index] - 1].name).join(", ");
  }
  getFileCount(post: Post) {
    return post.files ? post.files.length : 0;
  }
  getTotalFileSize(post: Post) {
    return post.files?.map(f => f.size).reduce((p, r) => {
      p += r;
      return p;
    }, 0);
  }
  getToggleErrorMessage(postId: number) {
    if (this.expandableContentsMap[postId]?.shouldExpand === 'chart') {
      if (this.expandableContentsMap[postId]?.chartData == null) {
        return "Invalid file format or file size is too large.";
      }
      if (this.selectedFileMap[postId].length > 1) {
        return "Select only one file.";
      }
    }
    if (this.expandableContentsMap[postId]?.shouldExpand === 'post') {
      if (this.expandableContentsMap[postId]?.postContent == null) {
        return "Invalid post content.";
      }
    }
    return null;
  }
  getPost(postId: number) : Post {
    return this.postData.find(p => Number(p.id) === postId);
  }

  loadAllPost() {
    this.resetChartMap()
    let type = null;
    if (this.boardTypes != null && this.boardTypes.some(t => t > 0)) {
      type = this.boardTypes.reduce((p, r) => p * 10 + r, 0);
    }
    this.subscription?.unsubscribe();
    this.subscription = this.post
      .readAll(
        this.storeKey,
        this.currentPage,
        {
          type: type,
          keyword: this.searchKey,
          category: this.category
        }
      )
      .subscribe((data) => {
        this.postData = data.content;
        this.maxPage = data.totalPages;
        this.pageSize = data.size;
        data.content.forEach((post: Post) => {
          this.selectedFileMap[post.id] = [];
        });
      });
  }

  downloadFiles(e: MouseEvent, post: Post) {
    e.stopPropagation();
    e.preventDefault();
    const files = this.selectedFileMap[post.id];

    for (const file of files) {
      this.post.downloadFile(this.storeKey, post.id, file);
    }
  }
  downloadFile(e: MouseEvent, post: Post, file: File) {
    e.stopPropagation();
    e.preventDefault();
    this.post.downloadFile(this.storeKey, post.id, file);
  }

  resetChartMap() {
    this.expandableContentsMap = {} as {[key: number]: ExpandableContents};
    this.selectedFileMap = {};
    this.expandedPostId = null;
  }



}
