import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PostService } from './post.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationFilesService extends PostService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  private storeKey = "presentation-files";

  read(year: number | string, id: number | string): Observable<any> {
    return this.get([this.storeKey, year, id].join("/"));
  }

  readAll(): Observable<any> {
    return this.get(this.storeKey);
  }

  readAllByYear(year: number | string): Observable<any> {
    return this.get([this.storeKey, year].join("/"));
  }
}


/* import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PresentationFiles } from 'src/app/store/schema';
import { AbstractPostService } from './abstract-post.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationFilesService extends AbstractPostService<PresentationFiles> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  private storeKey = "presentation_files";

  read(year: number | string, id: number | string): Observable<any> {
    return this.get([this.storeKey, year, id].join("/"));
  }

  readAll(): Observable<any> {
    return this.get(this.storeKey);
  }

  readAllByYear(year: number | string): Observable<any> {
    return this.get([this.storeKey, year].join("/"));
  }
}
 */
