import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgressService } from './common/progress/progress.service';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private readonly progressBarId = 'navigate';

  constructor(private router: Router, private translate: TranslateService, private progress: ProgressService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('ko');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('ko');
  }

  ngOnInit(): void {
    this.progress.event(this.progressBarId).subscribe(({ timeout }) => {
      if (!timeout)
        return;

      /* this.popup.show("오류가 발생했습니다. 다시 시도해주세요.", () => {
        this.progress.stop();
      }); */
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.handleRouteEvent(event);
      });
  }

  updateThemeColor(color: string) {
    let metaTag = document.querySelector('meta[name="theme-color"]');

    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('name', 'theme-color');
      document.getElementsByTagName('head')[0].appendChild(metaTag);
    }

    metaTag.setAttribute('content', color);
  }

  private handleRouteEvent(event) {
    // FIXME Guard
    if (event instanceof NavigationStart) {
      this.progress.show(this.progressBarId, {
        errorLimitSec: 10
      });
    }

    const isNavigationEnd = [NavigationEnd, NavigationCancel, NavigationError].some(n => event instanceof n);

    if (isNavigationEnd) {
      this.progress.hide();
      window.scrollTo(0, 0);
    }

    if (event.url === '/announcement') {
      this.updateThemeColor('#000000');
    }

  }
}
