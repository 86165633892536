import { Injectable } from '@angular/core';
import {ApiGateway} from "../../../../../http/api-gateway";
import {HttpClient} from "@angular/common/http";
import {ProgressService} from "../../../../../common/progress/progress.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class JobMonitorService extends ApiGateway {
  constructor(
    protected http: HttpClient, private progress : ProgressService, private router: Router) {
    super(http);
  }
  protected get apiUrl(): string {
    return "api/v1/jobs";
  }
  getImageGenerationJobExecutions(dates: string[]) {
    return this.get("image-generation/result", {}, {dates: dates});
  }
  runImageGenerationJobAsync(date: string) {
    return this.post("image-generation/run-async", {}, {date: date});
  }
  runImageGenerationStepAsync(date: string, step: string) {
    return this.post(`image-generation/run-async/${date}/${step}`, {}, {});
  }
  stopImageGenerationJob(date: string) {
    return this.post("image-generation/stop", {}, {date: date});
  }
}
