import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as dateFnsFp from 'date-fns/fp';
import * as _ from "lodash";
import { ProgressService } from 'src/app/common/progress/progress.service';
import { OpenApiService } from 'src/app/http/open-api.service';

@Component({
  selector: 'app-rader',
  templateUrl: './rader.component.html',
  styleUrls: ['./rader.component.css']
})
export class RaderComponent implements OnInit, OnDestroy {

  serviceKey = "4%2BnxKCEC1Y9rJHeg4%2BDmfM3cRHuVWNBJPk0Oob4C%2BeFCCvkTxlSRR3ZGNEGspHhb0DVg%2B9UBPg30%2FS9L%2FEJBKA%3D%3D";
  imageArr: string[] = [];
  imageUrl: string;
  index: number = 0;
  header = "Radar";

  timerId: any = null;

  delayThreshold = 5;

  constructor(private api: OpenApiService, private progress: ProgressService, private router : Router) { }

  ngOnInit(): void {
    this.progress.show("waitingApiResponse2", {
      errorLimitSec: 10
    });

    const apiUrl = "http://apis.data.go.kr/1360000/RadarImgInfoService/getCmpImg?serviceKey="+this.serviceKey+"&pageNo=1&numOfRows=10&dataType=JSON&data=CMP_WRC&time=";
    let formatter = dateFnsFp.format("yyyyMMdd");

    const today = new Date();
    const observable1 = this.api.list(apiUrl + formatter(today));

    observable1.subscribe(response => {
      this.imageArr = this.getImageUrl(response as any);
    },
      _error => {
        this.imageArr = [];
      },
      () => {
        this.updateInterval();
        this.progress.hide();
      });

  }


  getImageUrl(imageArray: string[]) {
    if (imageArray.length <= 0)
      return [];

    const temp = [];

    for (let i = imageArray.length - 1; i >= 0; i -= 2) {
      if (temp.length >= 18)
        break;

      temp.unshift(imageArray[i].replace("http", "https"));
      }

    return temp;
  }

  sec: number = -1;

  updateInterval() {
   // clearInterval(this.timerId);

    let temp = 0;

    this.timerId = setInterval(() => {
      this.imageUrl = this.imageArr[this.index];
      this.index = ++this.index % this.imageArr.length;

      const dataLength = this.imageArr.length - 1;
      if (this.index >= dataLength || temp > 0) {
        this.index = dataLength;
        temp++;

        if (temp >= this.delayThreshold) {
          this.index = 0;
          temp = 0;
        }
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }


}
