
<div class="columns range-slider-wrapper" *ngIf="!isHiddenSlider">
  <div class="column buttons-wrapper is-flex">
    <app-date-controls [date]="currentDate" (dateChange)="onChangeDate($event)"></app-date-controls>
    <div class="buttons has-addons is-align-content-flex-end is-flex-wrap-nowrap">
        <button class="button" (click)="clickPlayControls('backward')">
            <i class="fas fa-step-backward fa-sm"></i>
        </button>

        <button class="button" (click)="clickPlayControls('-' + step2*2)" *ngIf="step2*2 != interval">
            -{{ step2*2 }}{{ this.unit }}
        </button>

        <button class="button"
            (click)="clickPlayControls(currentIntervalHour(false))">
            {{ currentIntervalHour(false) }}
        </button>

        <button class="button" (click)="clickPlayControls('play')" *ngIf="!play"
            [disabled]="!isAvailable">
            <i class="fas fa-play fa-xs"></i>
        </button>

        <button class="button" (click)="clickPlayControls('pause')" *ngIf="play">
            <i class="fas fa-pause fa-sm"></i>
        </button>

        <button class="button"
            (click)="clickPlayControls(currentIntervalHour(true))">
            {{ currentIntervalHour(true) }}
        </button>

        <button class="button" (click)="clickPlayControls('+' + step2*2)" *ngIf="step2*2 != interval">
            +{{ step2*2 }}{{ this.unit }}
        </button>

        <button class="button" (click)="clickPlayControls('forward')">
            <i class="fas fa-step-forward fa-sm"></i>
        </button>

        <button class="button" (click)="clickPlayControls('reset')">
            <i class="fas fa-undo-alt fa-sm"></i>
        </button>
    </div>
  </div>
  <div class="column" style="flex-grow: 4">
    <div class="range-slider">
      <ngx-slider
        [(value)]="minValue"
        [(highValue)]="maxValue"
        [options]="options"
        (userChangeEnd)="onChangeSliderValue($event)"
      >
      </ngx-slider>
    </div>
  </div>
</div>
