import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HighchartsChartModule} from "highcharts-angular";
import {HighchartsComponent} from "./highcharts.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [HighchartsComponent],
  imports: [
    CommonModule,
    HighchartsChartModule,
    FormsModule,
  ],
  exports: [HighchartsComponent]
})
export class HighchartsModule { }
