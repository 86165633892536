<nav class="navbar is-transparent" role="navigation" aria-label="main navigation">
    <div class="navbar-brand is-align-items-center is-flex">
        <a class="navbar-item" [routerLink]="[ '/' ]">
<!--             <img src="assets/images/logo.png">-->
<!--             <img src="https://via.placeholder.com/800x500" />-->
        </a>

        <a role="button" class="navbar-burger mr-2" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
            [ngClass]="{'is-active': isExpanded}" (click)="toggle()">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarBasic" class="navbar-menu padding-auto" [ngClass]="{'is-active': isExpanded}">
        <div class="navbar-start">
            <a class="navbar-item py-4" [routerLink]="[ '/' ]"  [routerLinkActive]="['has-text-info', 'has-text-weight-bold']" [routerLinkActiveOptions]="{exact:true}">
                HOME
            </a>
            <!-- TODO: uncomment and remove *ngIf when announcement is ready -->
<!--            <a class="navbar-item py-4" [routerLink]="[ '/announcement' ]" [routerLinkActive]="['has-text-info', 'has-text-weight-bold']" [routerLinkActiveOptions]="{exact:true}"-->
<!--               *ngIf="auth.hasAuthentication()">-->
<!--                ANNOUNCEMENT-->
<!--            </a>-->
            <a class="navbar-item py-4" [routerLink]="[ '/routine-data-collected' ]" [routerLinkActive]="['has-text-info', 'has-text-weight-bold']" *ngIf="auth.hasAuthentication()">
                ROUTINE DATA COLLECTED
            </a>
            <a class="navbar-item py-4" [routerLink]="[ '/field-campaign-data-collected' ]" [routerLinkActive]="['has-text-info', 'has-text-weight-bold']" *ngIf="auth.hasAuthentication()">
                FIELD CAMPAIGN DATA COLLECTED
            </a>
            <a class="navbar-item py-4" [routerLink]="[ '/admin' ]"  [routerLinkActive]="['has-text-info', 'has-text-weight-bold']" *ngIf="auth.hasStaffRole()">
                ADMIN
            </a>
        </div>

        <div class="navbar-end">
            <!-- <div class="navbar-item">
                <div class="buttons has-addons are-small is-right">
                    <ng-container *ngFor="let lang of langs ; let i = index">
                        <button class="button is-white" (click)="changeLang(lang)">
                            <img [src]="'assets/images/flag/' + countries[i] + '-flag-small.png'" [ngClass]="{'current-lang': currentLang == lang}">
                        </button>
                    </ng-container>
                </div>
            </div> -->

            <div class="navbar-item mr-4">
                <ng-container *ngIf="!auth.hasAuthentication(); else elseTemplate">
                    <div class="buttons are-small">
                        <a class="button is-info has-text-weight-bold" href="https://www.sijaq.org">
                            OTHER CAMPAIGNS
                        </a>
                        <a class="button is-info has-text-weight-bold" [routerLink]="[ '/signin' ]">
                            SIGN IN
                        </a>
                        <!-- <a class="button is-link has-text-weight-bold" [routerLink]="[ '/signup' ]">
                            Sign up
                        </a> -->
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    <div class="buttons are-small is-right">
                        <a class="button is-info has-text-weight-bold" href="https://www.sijaq.org">
                            OTHER CAMPAIGNS
                        </a>
                        <a class="button is-info has-text-weight-bold" [routerLink]="[ '/profile']">
                            PROFILE
                        </a>
                        <a class="button is-info has-text-weight-bold" (click)="signout()">
                            SIGN OUT
                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</nav>
<div class="nav-margin"></div>
