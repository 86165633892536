import { Component, OnInit } from '@angular/core';
import {JobMonitorService} from "../job-monitor.service";

@Component({
  selector: 'app-modeling-monitor',
  templateUrl: './modeling-monitor.component.html',
  styleUrls: ['./modeling-monitor.component.css']
})
export class ModelingMonitorComponent implements OnInit {

  constructor(private jobService : JobMonitorService) { }
  results: any;
  date: string;
  step: string;
  steps = [
    {label: "ALL", value: "0"},
    {label: "Step 1: spatial 27km", value: "1"},
    {label: "Step 2: spatial 9km", value: "2"},
    {label: "Step 3-1: flight DC-8", value: "3-1"},
    {label: "Step 3-2: flight C90GT", value: "3-2"},
    {label: "Step 3-3: flight NARA", value: "3-3"},
    {label: "Step 3-4: flight 1900D", value: "3-4"},
    {label: "Step 4: tagged", value: "4"},
    {label: "Step 5: ground timeseries", value: "5"},
    {label: "Step 6: south-east", value: "6"}
  ]

  formatDate() {
    return this.date.replace(/-/g, "");
  }

  ngOnInit(): void {
  }

  onClickStartButton() {
    if (this.step === "0") {
      this.jobService.runImageGenerationJobAsync(this.formatDate()).subscribe(
        (data: any) => {
          console.log(data);
        }
      )
    }
    else {
      this.jobService.runImageGenerationStepAsync(this.formatDate(), this.step).subscribe(
        (data: any) => {
          console.log(data);
        }
      )
    }
  }

  onClickStopButton() {
    this.jobService.stopImageGenerationJob(this.formatDate()).subscribe(
      (data: any) => {
        console.log(data);
      }
    )
  }

  onChangeDate(event: any) {
    console.log(event);
  }
  onChangeSteps(event: any) {
    console.log(event);
  }

}
