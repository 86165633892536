<div class="tabs is-toggle is-small" style="overflow-x: hidden;">
  <ul>
    <li routerLinkActive="is-active"  *ngFor="let tabHeader of tabHeaders">
      <a class="is-flex" [routerLink]="[ '/routine-data-collected', 'satellite-data', 'cloud', (tabHeader.value)]">
        <p>{{ tabHeader.name }}</p>
      </a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>
