<footer class="footer">
  <div class="columns padding-auto" style="width: 100%">
        <!-- <div class="column is-2 ml-3 mr-3">
            <img src="https://via.placeholder.com/400x200?text=footer+logo" />
        </div> -->
        <div class="column is-7">
            <div class="is-size-6">
              <i class="fas fa-map-marker-alt fa-sm" style="width: 10px"></i>
              &nbsp; 501-506, Seoul National University, 1 Gwanak-ro, Gwanak-gu, Seoul
            </div>
            <div class="columns is-size-6 is-gapless">
                <div class="mr-5">
                    <i class="fas fa-envelope fa-sm" style="width: 10px"></i>
                    &nbsp; 08826
                </div>
<!--                <div class="mr-5">-->
<!--                    <i class="fas fa-phone fa-sm" style="width: 10px"></i>-->
<!--                    &nbsp; +82-2-880-6712-->
<!--                </div>-->
<!--                <div class="mr-3">-->
<!--                    <i class="fas fa-fax"></i>: 052-217-2836-->
<!--                </div>-->
                <div class="mr-5">
                    <i class="fas fa-mail-bulk" style="width: 10px"></i>
                  <a href="mailto:ss99@snu.ac.kr">&nbsp; ss99@snu.ac.kr</a>
                </div>
            </div>
            <div class="is-size-6">
                Copyright (C) <span class="has-text-weight-bold">SIJAQ.</span> All rights reserved
            </div>
        </div>
      <div class="column is-5">
        <div> System related Inquiries </div>
        <div class="columns is-size-6 is-gapless mb-0">
          <div class="mr-5">
            <i class="fas fa-phone fa-sm" style="width: 10px"></i>
            &nbsp; 010-9219-8699
          </div>
          <!--                <div class="mr-3">-->
          <!--                    <i class="fas fa-fax"></i>: 052-217-2836-->
          <!--                </div>-->
          <div class="mr-5">
            <i class="fas fa-mail-bulk" style="width: 10px"></i>
            <a href="mailto:hjseo@miraeclimate.com">&nbsp; hjseo@miraeclimate.com</a>
          </div>
        </div>
        <div class="columns is-size-6 is-gapless mt-0">
          <div class="mr-5">
            <i class="fas fa-phone fa-sm" style="width: 10px"></i>
            &nbsp; 010-2470-9894
          </div>
          <!--                <div class="mr-3">-->
          <!--                    <i class="fas fa-fax"></i>: 052-217-2836-->
          <!--                </div>-->
          <div class="mr-5">
            <i class="fas fa-mail-bulk" style="width: 10px"></i>
            <a href="mailto:hyun@miraeclimate.com">&nbsp; hyun@miraeclimate.com</a>
          </div>
        </div>
      </div>
    </div>
</footer>
