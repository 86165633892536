import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { GallerySetting } from 'src/app/common/gallery-setting';
import { PostService } from 'src/app/http/post.service';
import { Post } from 'src/app/store/schema';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  gallery: Post;
  galleryImages: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions[] = GallerySetting.options;

  constructor(private post: PostService, private router: Router) { }

  ngOnInit(): void {
    // this.post.readAll("announcements", 1, {
    //   size: 10
    // }).subscribe(({ content }) => {
    //   this.announcements = content;
    // });

    // this.galleryImages = [
    //   { medium: "assets/images/home/title.jpg", description: "test!<br>Some events" },
    //   { medium: "assets/images/home/title.jpg" }
    // ]
    this.galleryImages = [
      { medium: "assets/images/home/main-bottom.jpg" },
      { medium: "assets/images/home/main-topimg.jpg" }
    ]
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    element.scrollIntoView({behavior:"smooth"});
  }

  public readonly window = window;
}
