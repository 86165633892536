import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { File, Post } from '../store/schema';
import { ApiGateway } from './api-gateway';

@Injectable({
  providedIn: 'root'
})
export class PostService extends ApiGateway {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get apiUrl(): string {
    return "post";
  }

  create(path: string, value: { post: Post, files?: globalThis.File[] }, header?: any): Observable<any> {
    return this.post(path, this.valueToFormData(value), {}, header);
  }

  edit(path: string, value: { post: Post, files?: globalThis.File[] }, header?: any): Observable<any> {
    return this.put(path, this.valueToFormData(value), {}, header);
  }

  private valueToFormData(value: { post: Post, files?: globalThis.File[] }) {
    const formData = new FormData();

    formData.append('post', JSON.stringify(value.post));

    if (value.files) {
      for (const file of value.files) {
        formData.append('files', file);
      }
    }
    return formData;
  }

  delete(path: string, id: number | string) {
    return super.delete([path, id].join("/"));
  }

  updateStatus(path: string, id: number | string, status: { deleted: boolean }) {
    return this.put([path, id].join("/"), {}, status);
  }

  downloadFile(path: string, postId: number | string, file: File) {
    super.download([path, "download", postId, file.id].join("/"), file.name).subscribe();
  }

  read(path: string, id: number | string, isUpdateViewCount: boolean = true): Observable<any> {
    return this.get([path, id].join("/"), {
      view: isUpdateViewCount
    });
  }

  readAll(path: string, page: number, criteria?: { size?: number, category?: string, keyword?: string, type?: string }): Observable<any> {
    return this.get(path, criteria ? {page: page, ...criteria} : {page: page});
  }

  readChart(path: string, id: number, file: File): Observable<any> {
    return this.get([path, id, file.id, "chart"].join("/"));
  }
}
