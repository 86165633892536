import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { TableModule } from 'src/app/common/table/table.module';
import { TranslateDirectiveModule } from 'src/app/common/translate-directive/translate-directive.module';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { UnderConstructionModule } from '../under-construction/under-construction.module';
import { ComparisonModule } from './comparison/comparison.module';
import { EastAsiaModule } from './ensemble/east-asia/east-asia.module';
import {SouthEastAsiaModule} from "./ensemble/south-east-asia/south-east-asia.module";

@NgModule({
  imports: [
    CommonModule,
    EastAsiaModule,
    SouthEastAsiaModule,
    CopyrightModule,
    TimeRangeSliderModule,
    ValidationModule,
    UnderConstructionModule,
    TableModule,
    TranslateDirectiveModule,
    ComparisonModule
  ]
})
export class ModelingModule { }
