import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import * as _ from "lodash";
import 'rxjs/add/observable/of';
import {Control} from "../../highcharts/highcharts.component";

@Component({
  selector: 'app-checkbox-buttons',
  templateUrl: './checkbox-buttons.component.html',
  styleUrls: ['./checkbox-buttons.component.scss']
})
export class CheckboxButtonsComponent implements OnInit {

  private _controls?: Control<any>[];

  @Input()
  set controls(value: Control<any>[]) {
    this._controls = _.cloneDeep(value);
  }

  get controls() {
    return this._controls as Control<any>[];
  }

  @Output()
  controlsChange = new EventEmitter<Control<any>[]>();

  @Input()
  alignment: 'left' | 'centered' | 'right' = 'centered';

  formGroup = this.fb.group({
    control: this.fb.array([])
  });

  constructor(private fb: FormBuilder, private changeRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    const tempForm = this.fb.group({});
    const checkedControls = this.controls.map(control => new FormControl(control.checked));
    console.log(checkedControls)
    tempForm.addControl('control', new FormArray(checkedControls));
    this.formGroup = tempForm;
    this.changeRef.detectChanges();

    // 초기화 후 처음 알려주기
    this.controlsChange.emit(this.controls);

    this.formArray.valueChanges.subscribe((bs: boolean[]) => {
      bs.forEach((b, i) => this.controls[i].checked = b);
      this.controlsChange.emit(this.controls);
    });
  }

  get formArray(): FormArray {
    return this.formGroup.get('control') as FormArray;
  }

  toggle(index: number) {
    const control = this.formArray.controls[index];
    control.setValue(!control.value);
  }
}
