import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as bulmaCalendar from "bulma-calendar";
import * as dateFns from "date-fns";
import { Subscription } from 'rxjs';
import {size} from "lodash";

const unixTime = dateFns.fromUnixTime(0);
const unixTimeForEndOfDay = dateFns.endOfDay(unixTime);

class DateTimeInfo {
  private _startDate?: Date;
  public get startDate(): Date {
    return this._startDate ?? new Date();
  }
  public set startDate(value: Date) {
    this._startDate = value;
  }

  private _startTime?: Date;
  public get startTime(): Date {
    return this._startTime ?? unixTime;
  }
  public set startTime(value: Date) {
    this._startTime = value;
  }

  private _endDate?: Date;
  public get endDate(): Date {
    return this._endDate ?? new Date();
  }
  public set endDate(value: Date) {
    this._endDate = value;
  }

  private _endTime?: Date;
  public get endTime(): Date {
    return this._endTime ?? unixTimeForEndOfDay;
  }
  public set endTime(value: Date) {
    this._endTime = value;
  }
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements AfterViewInit, OnChanges {

  /* @ViewChild('calendar', { static: true })
  calendarElementRef!: ElementRef; */

  @Input() offsetHour: number = 0;

  @ViewChild('calendarContainer', {static: true})
  calendarContainerElementRef!: ElementRef;

  private calendar!: bulmaCalendar;

  private options: bulmaCalendar.Options = {
    type: 'date',
    dateFormat: 'yyyy.MM.dd (E) ',
    startDate: new Date(),
    endDate: new Date(),
    startTime: dateFns.startOfDay(new Date()),
    endTime: dateFns.endOfDay(new Date()),

    minuteSteps: 1,
    color: "info",

    // FIXME min, max 설정시 year 선택 오류 발생
    enableYearSwitch: false,

    navigationMonthFormat: ' ',
    navigationYearFormat: 'yyyy MM',
    headerMonthYearFromat: 'yyyy MM',
    showClearButton: false,
    /* closeOnOverlayClick: false,
    closeOnSelect: false, */

    /* closeOnSelect: true,
    showFooter: false, */

    lang: 'en',
    // todayLabel: '오늘',
    // cancelLabel: '닫기',
    // validateLabel: '확인',
    // nowLabel: '지금'
  };

  @Input()
  set type(value: 'date' | 'time' | 'datetime') {
    this.options.type = value;
  }

  @Input()
  set isRange(value: boolean) {
    this.options.isRange = value;
  }

  @Input()
  set allowSameDayRange(value: boolean) {
    this.options.allowSameDayRange = value;
  }

  @Input()
  set dateFormat(value: string) {
    this.options.dateFormat = value;
  }

  @Input()
  set timeFormat(value: string) {
    this.options.timeFormat = value;
  }

  @Input()
  set displayMode(value: 'default' | 'dialog' | 'inline') {
    this.options.displayMode = value;
  }

  @Input()
  set showHeader(value: boolean) {
    this.options.showHeader = value;
  }

  @Input()
  set headerPosition(value: 'top' | 'bottom') {
    this.options.headerPosition = value;
  }

  @Input()
  set showFooter(value: boolean) {
    this.options.showFooter = value;
  }

  @Input()
  set showTodayButton(value: boolean) {
    this.options.showTodayButton = value;
  }

  @Input()
  set showClearButton(value: boolean) {
    this.options.showClearButton = value;
  }

  @Input()
  set enableMonthSwitch(value: boolean) {
    this.options.enableMonthSwitch = value;
  }

  /* @Input()
  set enableYearSwitch(value: boolean) {
    this.options.enableYearSwitch = value;
  } */

  @Input()
  set startDate(value: Date | string | number | 'now' | 'today') {
    this.options.startDate = this.adjustInitDate(value, 'min');
    // this.options.startDate = new Date(2020, 1, 1);
  }

  @Input()
  set endDate(value: Date | string | number | 'now' | 'today') {
    this.options.endDate = this.adjustInitDate(value, 'max');
  }

  @Input()
  set startTime(value: Date | string | number | 'now' | 'today') {
    this.options.startTime = this.adjustInitDate(value, 'min');
  }

  @Input()
  set endTime(value: Date | string | number | 'now' | 'today') {
    this.options.endTime = this.adjustInitDate(value, 'max');
  }

  @Input()
  set minDate(value: Date | string | number) {
    this.options.minDate = this.adjustInitDate(value, 'min');
  }

  @Input()
  set maxDate(value: Date | string | number) {
    this.options.maxDate = this.adjustInitDate(value, 'max');
  }

  private adjustInitDate(value: Date | string | number, today: 'min' | 'max') {
    let date;
    switch (value) {
      case 'today':
        date = today == 'min' ? dateFns.startOfToday() : dateFns.endOfToday();
        // date = dateFns.addDays(date, 1);
        break;

      case 'now':
        date = new Date();
        break;

      default:
        date = new Date(value);
        break;
    }
    if (this.offsetHour != 0)
      date = dateFns.addHours(date, this.offsetHour);
    return date;
  }

  /**
   * string : "yyyy.MM.dd" or "dd/MM/yyyy"
   * number : timestamp
   */
  @Input()
  set disabledDates(value: Date[] | string[] | number[]) {
    this.options.disabledDates = value;
  }

  /**
   * start : 0
   */
  @Input()
  set disabledWeekDays(value: number[]) {
    this.options.disabledWeekDays = value;
  }

  /**
   * @default 0 // sunday
   */
  @Input()
  set weekStart(value: number) {
    this.options.weekStart = value;
  }

  @Input()
  set minuteSteps(value: number) {
    this.options.minuteSteps = value;
  }

  @Input()
  border: boolean = true;

  @Output()
  ready = new EventEmitter<{ start: Date, end: Date }>();

  @Output()
  event = new EventEmitter<{ start: Date, end: Date }>();

  @Output()
  startDateChange = new EventEmitter<Date>();

  @Output()
  endDateChange = new EventEmitter<Date>();

  constructor() { }

  debugSubscription?: Subscription;

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.debugSubscription?.unsubscribe();
  }

  private objToDateTimeInfo(obj: bulmaCalendar | bulmaCalendar.Options): DateTimeInfo {
    return {
      startDate: obj.startDate as Date,
      endDate: obj.endDate as Date,
      startTime: obj.startDate as Date,
      endTime: obj.endTime as Date
    }
  }

  // 둘 다 날짜에 대해서는 같은 이름의 Property를 갖고 있음
  private adjustDatesFromCalendarObject(obj: bulmaCalendar | bulmaCalendar.Options) {
    const dateTimeInfo = this.objToDateTimeInfo(obj);

    let startDateTime = null;
    let endDateTime = null;

    if (this.options.type == 'time') {
      startDateTime = dateTimeInfo.startTime;
      endDateTime = this.calendar.isRange() ? dateTimeInfo.endTime : new Date(dateTimeInfo.startTime);
    }
    else {
      const startTime = dateTimeInfo.startTime;

      startDateTime = dateFns.set(dateTimeInfo.startDate, {
        hours: startTime.getHours(),
        minutes: startTime.getMinutes(),
        seconds: startTime.getSeconds()
      });

      if (this.calendar.isRange()) {
        const endTime = dateTimeInfo.endTime;

        endDateTime = dateFns.set(dateTimeInfo.startDate, {
          hours: endTime.getHours(),
          minutes: endTime.getMinutes(),
          seconds: endTime.getSeconds()
        });
      }
      else {
        endDateTime = new Date(startDateTime);
      }
    }

    return {
      start: startDateTime,
      end: endDateTime
    }
  }

  private attach() {
    const container = this.calendarContainerElementRef.nativeElement as HTMLElement;

    while (container.hasChildNodes()) {
      container.removeChild(container.firstChild as any);
    }

    const element = document.createElement('input');
    element.setAttribute('type', 'date');
    container.append(element);

    this.calendar = bulmaCalendar.attach(element as any, this.options)[0];


    this.calendar.on('select', ({ data }) => {
      this.calendar.save();

      // save 하면 dialog가 종료됨
      if (this.calendar.isRange() || this.options.type == 'datetime')
        this.calendar.show();

      const adjustedDates = this.adjustDatesFromCalendarObject(data);
      this.event.emit(adjustedDates);

      this.startDateChange.emit(adjustedDates.start);
      if (adjustedDates.end)
        this.endDateChange.emit(adjustedDates.end);
    });

    const todayButton = document.getElementsByClassName("datetimepicker-footer-today")[0] as HTMLElement;
    todayButton.onclick = _ => this.event.emit(this.adjustDatesFromCalendarObject(this.calendar as bulmaCalendar));

    if (!this.border) {
      const element = document.getElementsByClassName("datetimepicker-dummy-wrapper")[0] as HTMLElement;
      element.style.border = "none";
    }
  }

  ngAfterViewInit(): void {
    this.attach();
    this.ready.emit(this.adjustDatesFromCalendarObject(this.calendar));
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.calendar == null)
      return;

    this.calendar.date = this.adjustDatesFromCalendarObject(this.options);

    // save 하면 dialog가 종료됨
    /* if (this.calendar.isRange() || this.options.type == 'datetime') {
      this.calendar.show();
    } */

    if (!this.calendar.isRange()) {
      // 외부 버튼으로 변경이 안되기때문에 새로 덧붙임.
      this.attach();
    }
  }

  protected readonly outerWidth = outerWidth;
  protected readonly size = size;
}
