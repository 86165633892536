import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as L from 'leaflet';
import {PostService} from "../../../http/post.service";
import {FlightInfo, FlightInfoService} from "../../../services/flight-info.service";
import * as d from 'date-fns';
import {environment} from "../../../../environments/environment";

export type Flight = 'DC-8' | '1900D'
export type RouteData = {
  name: Flight,
  status: 'uncertain' | 'confirmed'
  data: {
    base: string,
    time?: Date,
    lat: number,
    lon: number,
    alt?: number,
    landmark?: string
    index?: number
  }[]};
export type FLightSummary = {
  name: Flight,
  start: string,
  end: string,
  landmarks: {
    time?: Date,
    landmark?: string,
    status?: string,
    name?: string
  }[],
  currentIndex?: number
};

@Component({
  selector: 'app-flight-plan',
  templateUrl: './flight-plan.component.html',
  styleUrls: ['./flight-plan.component.css']
})
export class FlightPlanComponent implements AfterViewInit, OnInit, OnChanges {

  @ViewChild('main') main: ElementRef;
  @ViewChild('timeline') timeline: QueryList<ElementRef>;
  showArrow = true;

  // 2024-02-13T08:00:00 (KST)
  testDate = new Date();

  private map: any;
  flightMarkers: { [key in Flight]: any } = {} as any;
  flightPaths: { [key in Flight]: any } = {} as any;
  flightLandmarks: { [key in Flight]: any[] } = {'DC-8': [], '1900D': []};
  flightTrackers: { [key in Flight]: any } = {} as any;
  shouldShowFlight: { [key in Flight]: boolean } = {'DC-8': true, '1900D': true};
  isDefaultFlight: boolean;
  allTimeIsNull: boolean;

  @Input() flightRoutes: RouteData[] = [];
  swiperConfig = {
    pagination: {
      clickable: true,
      el: '.swiper-pagination'
    },
    touchEventsTarget: 'container',
    loop: false,
    spaceBetween: 30,
    passiveListeners: false,
    slidesPerView: 1,
    simulateTouch: true,
    updateOnWindowResize: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
      }
    }
  }

  isColumn() {
    return window?.innerWidth < 768;
  }

  stopBubble(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  makeIcon(url, size: [number, number], anchor: [number, number]) {
    return L.icon({
      iconUrl: url,
      iconSize: size,
      iconAnchor: anchor
    });
  }

  addMarker(map: any, flight: Flight, lat: number, lon: number, name: string, icon?: any) {
    const latlng = L.latLng(lat, lon);
    if (!map.getBounds().contains(latlng)) return;
    if (this.flightMarkers[flight]) this.removeMarker(flight);
    this.flightMarkers[flight] = !!icon === true
      ? L.marker([lat, lon], {icon: icon}).addTo(map)
      : L.marker([lat, lon]).addTo(map);
  }

  updateMarker(flight: Flight, newLocation: [number, number], direction: number) {
    const [lat, lon] = newLocation;
    this.flightMarkers[flight].remove();
    this.flightMarkers[flight] = new L.Marker([lat, lon], {...this.flightMarkers[flight].options, rotateOrigin: 90})
      .addTo(this.map);
  }

  removeMarker(flight: Flight) {
    this.flightMarkers[flight].remove();
  }

  addPath(map: any, flight: Flight, latlngs: [number, number][], color: string, weight: number) {
    this.flightPaths[flight] = L.polyline(latlngs, {color: color, weight: weight}).addTo(map);
  }

  updatePath(flight: Flight, latlngs?: [number, number][], color?: string, weight?: number) {
    !!latlngs && this.flightPaths[flight].setLatLngs(latlngs);
    !!color && this.flightPaths[flight].setStyle({color: color});
    !!weight && this.flightPaths[flight].setStyle({weight: weight});
  }

  removePath(flight: Flight) {
    this.flightPaths[flight].remove();
  }

  makeButton(map: any, position: string, text: string, html, onClick: () => void) {
    const button = L.control({position: position});
    button.onAdd = () => {
      const div = L.DomUtil.create('div');
      div.innerHTML = html;
      div.onclick = onClick;
      return div;
    }
    button.addTo(map);
  }

  private initMap() {
    this.map = L.map('map', {
      center: [37.5665, 126.9780],
      zoom: 6.2,
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });
    const originalInitTile = L.GridLayer.prototype._initTile;
    L.GridLayer.include({
      _initTile: function (tile: any) {
        originalInitTile.call(this, tile);
        const tileSize = this.getTileSize();
        tile.style.width = tileSize.x - 0.45 + 'px';
        tile.style.height = tileSize.y - 0.45 + 'px';
      }
    });
    tiles.addTo(this.map);
  }

  getNonNull<T>(arrObj: T[], key: keyof T) {
    return arrObj.map((obj: any) => obj[key]).filter((value: any) => (!!value && value !== ''));
  }

  convertDMS2DD(minutes: number, seconds: number) {
    return minutes / 60 + seconds / 3600;
  }

  parseMalFormatLatLng(input) {
    // 입력 문자열에서 숫자와 구분 기호를 추출하기 위한 정규 표현식
    const regex = /(\d+)[^\d]+(\d+)[^\d]+(\d+)/;
    const matches = input.match(regex);

    if (matches && matches.length >= 4) {
      // 추출된 시, 분, 초
      const hours = matches[1];
      const minutes = matches[2];
      const seconds = matches[3];

      return this.convertDMS2DD2(Number(hours), Number(minutes), Number(seconds));
    } else {
      return null; // 적합한 형식이 아닐 경우 null 반환
    }
  }

  convertDMS2DD2(hours: number, minutes: number, seconds: number) {
    return hours + (minutes / 60) + (seconds / 3600);
  }

  async importFlightData() {
    const now = (environment.production === true)
      ? new Date()
      : this.testDate;
    const todayYYYYMMDDKst = d.format(now, 'yyyyMMdd');
    const flightNames: Flight[] = ['DC-8', '1900D']
    let routes = [];
    let status = 'uncertain'
    for (const flightName of flightNames) {
      const query = {
        flightName: flightName,
        date: todayYYYYMMDDKst
      }
      let flightInfos;
      try {
        flightInfos = await this.flightInfoService.getFlightInfo(query).toPromise();
        if (flightInfos.length === 0) throw new Error('No flight info');
        status = 'confirmed';
      } catch (e) {
        flightInfos = await this.flightInfoService.getFlightInfo({
          flightName: flightName,
          date: '20240101'
        }).toPromise();
        status = 'uncertain';
        if (flightInfos.length === 0) {
          routes.push({name: flightName, data: [], status: 'uncertain'});
          continue;
        }
      }
      const data = flightInfos.map((flightInfo: FlightInfo) => {
        const baseTime = flightInfo.baseTime;
        if (flightInfo.timeInUtc === null) status = 'uncertain';
        const localTime = (flightInfo.timeInUtc === null) ? null : new Date(flightInfo.timeInUtc);
        const latitude = flightInfo.latitude;
        const longitude = flightInfo.longitude;
        const region = flightInfo.region;
        const isLandmark = flightInfo.landmark;
        return {
          base: baseTime,
          time: localTime,
          lat: latitude,
          lon: longitude,
          ...(!!isLandmark === true ? {landmark: region} : {}),
          ...(flightInfos === undefined || flightInfos === null) ? {} : {index: flightInfo.seq}
        };
      })
      const routeInfo: RouteData = {
        name: flightName,
        status: status as 'confirmed' | 'uncertain',
        data: data
      }
      if (!!routeInfo.data?.length === false) {
        routes.push({name: flightName, data: [], status: 'uncertain'});
        continue;
      }
      routes.push(routeInfo)
    }
    this.flightRoutes = routes;
  }

  ngAfterViewInit(): void {
    this.importFlightData() // update this.flightRoutes
      .catch((e) => console.error(e))
      .finally(() => this.initMap())
      .then(() => this.onAfterDataImport())
      .catch((e) => console.error(e));
  }

  // toggle path and landmarks
  onToggleButtonClick(flightName: Flight, flightRoute: RouteData) {
    const flightPath = this.flightPaths[flightName];
    const flightMarker = this.flightMarkers[flightName];
    const landmarks = this.flightLandmarks[flightName];
    if (flightPath) {
      if (flightPath._map) {
        flightPath.remove();
      } else {
        flightPath.addTo(this.map);
      }
    }
    if (flightMarker) {
      if (flightMarker._map) {
        flightMarker.remove();
      } else {
        flightMarker.addTo(this.map);
      }
    }
    if (landmarks.length > 0) {
      landmarks.forEach((landmark: any) => {
        if (landmark._map) {
          landmark.remove();
        } else {
          landmark.addTo(this.map);
        }
      });
    }
    if (this.flightTrackers[flightName].status === 'uncertain') {
      this.flightTrackers[flightName] = null;
      return;
    }
    if (this.flightTrackers[flightName]) {
      clearInterval(this.flightTrackers[flightName]);
      this.flightTrackers[flightName] = null;
    } else {
      this.addFlightTracker(flightName, 1000, flightRoute);
    }
  }

  addToggleButton(map: any, position: string, flightName: Flight, onClick: () => void, color?: string) {
    const button = L.control({position: position});
    button.onAdd = () => {
      const div = L.DomUtil.create('div');
      div.innerHTML = `
            <button class="button is-flex justify-content-start" onclick="onToggleButtonClick('${flightName}')">
                <i class="fa fa-plane mr-2"></i>
                <span>${flightName}</span>
                <span style="width: 100%"> </span>
            </button>`
        .replace(/(\r\n|\n|\r)/gm, '');
      div.querySelector('button').style.width = '100px';
      div.onclick = onClick;
      // 버튼색
      if (color) {
        div.querySelector('button').style.backgroundColor = color;
        div.querySelector('button').style.borderColor = color;
        div.querySelector('button').style.color = 'white';
      }
      return div;
    }
    button.addTo(map);
  }

  addLandmark(map: any, flight: Flight, lat: number, lon: number, color: string, tooltip?: string, zoomFilterLevel?: number) {
    const base = L.circleMarker([lat, lon], {
      radius: 5,
      color: color,
      weight: 1.5,
      fillOpacity: 1,
      fillColor: 'white'
    }).addTo(map);
    this.flightLandmarks[flight].push(base);
    if (tooltip) {
      const tooltipMask = L.circleMarker([lat, lon], {
        radius: 5,
        color: color,
        weight: 1.5,
        fillOpacity: 1,
        fillColor: 'white'
      }).addTo(map);
      this.flightLandmarks[flight].push(tooltipMask);
      const zoomFilter = () => {
        const zoom = this.map.getZoom();
        if (zoom >= zoomFilterLevel) {
          tooltipMask.bindTooltip(tooltip, {
            permanent: true,
            direction: 'top',
            className: 'leaflet-tooltip-arrow',
            opacity: 1
          });
          tooltipMask.getElement().style.visibility = 'visible';
        } else {
          tooltipMask.unbindTooltip();
          tooltipMask.getElement().style.visibility = 'hidden';
        }
      }
      map.on('zoomend', zoomFilter);
    }
  }

  removeAllLandmarks(flight: Flight) {
    this.flightLandmarks[flight] = [];
  }

  onAfterDataImport() {
    const colors = ['#0c3b8d', '#c51551'];
    this.flightRoutes.forEach((flightRoute: RouteData, i: number) => {
      const name = flightRoute.name
      this.addPath(this.map, name, flightRoute.data.map((data: any) => [data.lat, data.lon]), colors[i], 3);
      flightRoute.data.filter((data: any, index: number) => (!!data.landmark))
        .forEach((landmark: any) => {
          this.addLandmark(this.map, name, landmark.lat, landmark.lon, colors[i], landmark.landmark, 9.7);
        });
      this.addToggleButton(
        this.map,
        'topright',
        name,
        () => this.onToggleButtonClick(name, flightRoute),
        colors[i]
      );
      if (flightRoute['status'] === 'uncertain') {
        return;
      }
      const icon = this.makeIcon('./assets/images/plane.png', [30, 30], [15, 15]);
      this.addMarker(this.map, name, flightRoute.data[0].lat, flightRoute.data[0].lon, name, icon);
      this.addFlightTracker(name, 1000, flightRoute);
    });
  }

  addFlightTracker(flightName: Flight, interval: number, flightRoute: RouteData) {
    this.flightTrackers[flightName] = setInterval(() => {
      this.trackFlight(flightName, flightRoute);
    }, interval);
    return this.flightTrackers[flightName];
  }

  trackFlight(flightName: Flight, flightRoute: RouteData) {
    const now = (environment.production === true)
      ? new Date()
      // 시간은 현재 분초를 복사
      : new Date(this.testDate.setMinutes(new Date().getMinutes(), new Date().getSeconds()));
    const [startIndex, nextIndex] = this.getIndices(flightRoute, now);
    if (startIndex === -1) return;
    const index = this.getDecimalIndex(flightRoute, now.getTime(), startIndex, nextIndex);
    const before = this.getLocation(flightRoute, startIndex);
    const after = this.getLocation(flightRoute, nextIndex);
    const direction = this.getDirection(before, after);
    const newLocation = this.getLocation(flightRoute, index);
    this.updateMarker(flightName, newLocation, direction); // update flight marker
  }

  /**
   * @param flightRoute -> 비행 경로
   * @param index -> 소수점 index
   */
  getLocation(flightRoute: RouteData, index: number): [number, number] {
    const len = flightRoute.data.length;
    if (index < 0) return [flightRoute.data[0].lat, flightRoute.data[0].lon];
    if (index > len - 1) return [flightRoute.data[len - 1].lat, flightRoute.data[len - 1].lon];
    const startIndex = Math.floor(index);
    const endIndex = Math.ceil(index);
    const start = flightRoute.data[startIndex];
    const end = flightRoute.data[endIndex];
    const ratio = index - startIndex;
    const lat = start.lat + (end.lat - start.lat) * ratio;
    const lon = start.lon + (end.lon - start.lon) * ratio;
    return [lat, lon];
  }

  getDirection(before: [number, number], after: [number, number]) {
    const [lat1, lon1] = before;
    const [lat2, lon2] = after;
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    return Math.atan2(dLon, dLat) * 180 / Math.PI;
  }

  getIndices(flightRoute: RouteData, currentTime: Date) {
    const time = currentTime.getTime();
    const len = flightRoute.data.length;
    const startIndex = flightRoute.data.filter((data: any) => data.time.getTime() <= time).length - 1;
    const nextIndex = len <= startIndex + 1 ? startIndex : startIndex + 1;
    return [startIndex, nextIndex];
  }

  // 소수점의 index를 구한다 포인트 1과 2사이의 값이면 1~2 사이의 값이다.
  getDecimalIndex(flightRoute: RouteData, time, startIndex, nextIndex) {
    const len = flightRoute.data.length;
    const startTime = flightRoute.data[startIndex].time.getTime();
    const nextTime = flightRoute.data[nextIndex].time.getTime();
    if ((nextTime === startTime) && startTime === (len - 1)) return nextIndex; // already end
    if (time < startTime) return startIndex; // not started yet
    const ratio = (time - startTime) / (nextTime - startTime); // between index
    return startIndex + ratio;
  }

  getFlightStatus(dateList: Date[]) {
    let statusList = [];
    var cnt = 0;
    var isNull = false;

    const today = new Date();
    for (var i = 0; i < dateList.length; i++) {
      var date = dateList[i];

      if (date === null) {
        statusList.push('passed')
        isNull = true;
        break;
      } else if (date < today) {
        statusList.push('passed')
        cnt++;
      } else {
        statusList.push('')
      }
    }

    if (isNull) {
      var endIdx = dateList.length - statusList.length;
      for (var i = 0; i < endIdx; i++) {
        statusList.push('passed')
      }
    }

    if (!(isNull) && !(this.isDefaultFlight) && (cnt > 0)) {
      statusList[cnt - 1] = 'current';
    }

    return {
      statusList: statusList,
      currentIndex: cnt > 0 ? cnt - 1 : 0
    }
  }

  getPlanSummary(flightRoute: RouteData): FLightSummary {
    const start = flightRoute.data[0];
    const end = flightRoute.data[flightRoute.data.length - 1];
    const base = start?.base;
    this.allTimeIsNull = (start.time === null);

    const dateList = [];
    const landmarks = [];

    for (var i = 0; i < flightRoute.data.length; i++) {
      const data = flightRoute.data[i]
      // console.log(data)

      if (data.index === null) continue;

      dateList.push(data.time)

      let hhmm = '';
      if ((data.base === '20240101') && ((data.time === null))) {
        hhmm = '-(시):-(분)'
      } else if ((data.base !== '20240101') && ((data.time === null))) {
        hhmm = ''
      } else {
        hhmm = data.time.getHours().toString().padStart(2, '0') + ':' + data.time.getMinutes().toString().padStart(2, '0');
      }

      const landmark = {
        time: hhmm,
        name: data.landmark
      }

      landmarks.push(landmark);
    }

    const status = this.getFlightStatus(dateList);
    const statusList = status.statusList
    for (var i = 0; i < statusList.length; i++) {
      landmarks[i]['status'] = (base === '20240101') ? 'passed' : statusList[i];
    }

    this.isDefaultFlight = base === '20240101';

    return {
      name: flightRoute.name,
      start: (start.time === null) ? null : `${start.time.toLocaleDateString()} ${start.time.toLocaleTimeString()}`,
      end: (start.time === null) ? null : `${end.time.toLocaleDateString()} ${end.time.toLocaleTimeString()}`,
      landmarks: landmarks,
      currentIndex: status.currentIndex
    };
  }

  constructor(
    private postService: PostService,
    private flightInfoService: FlightInfoService,
  ) {
  }

  subscribeMarkerLocation(flightName: string) {
    this.flightInfoService.putSubject(flightName);
  }

  anchorToPoint(index: number) {
    if (this.timeline && this.timeline.toArray().length > 0) {
      const target = this.timeline.toArray()[index].nativeElement;
      target.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showArrow = false;
    }, 10000);
  }

  readonly window = window;
}
