<!--<ngx-loading></ngx-loading>-->
<section class="is-flex position-relative">
  <highcharts-chart
    class="chart"
    [Highcharts]="Highcharts"
    [(update)]=isUpdating
    [oneToOne]="true"
    [options]="options"
  ></highcharts-chart>
  <div class="is-flex is-flex-direction-column position-absolute" #optionsPanel>
    <div class="my-3"></div>
    <div class="field">
      <label class="label">Chart Type</label>
      <select class="control" [(ngModel)]="chartType" (ngModelChange)="onChangeChartType($event)">
        <option *ngFor="let option of chartTypeControls" [value]="option.value">{{option?.name}}</option>
      </select>
    </div>
    <div class="field">
      <label class="label">X Axis</label>
      <select class="control" [(ngModel)]="xAxis" (ngModelChange)="onChangeXAxis($event)">
        <option *ngFor="let option of xAxisSelectControls" [value]="option.value">{{option?.name}}</option>
      </select>
    </div>
    <div class="field">
      <label class="label">X Axis Type</label>
      <select class="control" [(ngModel)]="xAxisType" (ngModelChange)="onChangeXAxisType($event)">
        <option *ngFor="let option of xAxisTypeControls" [value]="option.value">{{option?.name}}</option>
      </select>
    </div>
    <div class="field">
      <label class="label">
        <span>Tooltip</span>
      </label>
      <input class="is-checkradio is-medium" type="checkbox" [(ngModel)]="showTooltip" (ngModelChange)="onChangeShowTooltip($event)">
      <span class="help">Show tooltip on hover.<br>This can cause performance issues with large datasets.</span>
    </div>
    <div class="field">
      <label class="label">
        <span>Zoom</span>
      </label>
<!--      <input class="is-checkradio is-medium" type="checkbox" [(ngModel)]="zoom" (ngModelChange)="onChangeZoom($event)">-->
      <span class="help">
        You can zoom in by dragging the mouse over the chart.
      </span>
    </div>
    <div class="field">
      <label class="label">
        <span>Timezone</span>
      </label>
      <select class="control" [(ngModel)]="timezone" (ngModelChange)="onChangeTimezone($event)">
        <option *ngFor="let option of timezoneControls" [value]="option.value">{{option?.name}}</option>
      </select>
    </div>
    <div class="field">
      <label class="label">
        <span>Select Series</span>
      </label>
      <span class="help">
        Click on the legend below to hide/show series.
      </span>
    </div>
  </div>
</section>


