import { Component, OnInit } from '@angular/core';
import {FlightInfoService} from "../../../../../services/flight-info.service";
import {PostService} from "../../../../../http/post.service";
import {AuthService} from "../../../../../http/auth.service";

@Component({
  selector: 'app-flight-path',
  templateUrl: './flight-path.component.html',
  styleUrls: ['./flight-path.component.css']
})
export class FlightPathComponent implements OnInit {

  constructor(
    private flightInfoservice : FlightInfoService,
    private postService: PostService,
    private authService: AuthService
  ) { }

  onClickUpdateButton(e) {
    this.updateFlightPath().then(r => {
      console.log('update success', r)
    }).catch(e => {
      console.log('update failed', e)
    })
  }

  get isAdmin() {
    return this.authService.isAdmin()
  }

  async updateFlightPath() {
    const storeKey = 'flight-path'; // 게시판 이름
    const splitter = "_";
    const extension = ".csv";
    const postsOnLastPage = await this.postService.readAll(storeKey, 1).toPromise()
    const flights = ['DC-8', '1900D']
    const tommorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0].replace(/-/g, '')
    console.log('tommorrow: ', tommorrow)
    // 가장 높은 postId를 가진 게시글의 파일들을 읽어온다.

    const posts = postsOnLastPage?.content
    const lastPost = posts.reduce((prev, current) => (prev.id > current.id) ? prev : current)
    console.log('lastPost: ', lastPost)
    for (const post of posts) {
      if (post.id === lastPost.id) {
        if (lastPost.files?.length === 0) {
          console.log('no files in the latest post')
        }
        else {
          for (const file of lastPost.files) {
            console.log('checking files in the latest post: ', file.name, 'flightName: ', file.name.split(splitter)[0], 'date: ', file.name.split(splitter)[1].split(extension)[0])
            try{
                await this.flightInfoservice.updateFlightInfo(post.id, file.id).toPromise()
            }
            catch(e) {
              console.error(e)
              console.log('updating failed: ', file.name)
            }
          }
        }
      }
      for (const file of post.files) {
        if (!file) continue;
        const flightName = file.name.split(splitter)[0];
        const date = file.name.split(splitter)[1].split(extension)[0];
        const defaultDate = '20240101'
        console.log('checking filename: ', file.name, 'flightName: ', flightName, 'date: ', date)
        // today 이거나 20240101 파일만 읽어온다.
        try{
          if (date === defaultDate || date === tommorrow) {
            await this.flightInfoservice.updateFlightInfo(post.id, file.id).toPromise()
          }
        }
        catch(e){
          console.error(e)
          console.log('updating failed: ', flightName, date)
        }
      }
    }
  }

  ngOnInit(): void {
  }

  onClickDeleteButton($event: MouseEvent) {
    console.log('delete button clicked')
    this.flightInfoservice.deleteAllFlightInfo().subscribe(r => {
      console.log('delete success', r)
    })
  }
}
