<app-time-range-slider
  [currentDate]="date"
  [unit]="'h'"
  [intervals]="[1]"
  [interval]="1"
  [step2]="3"
  (valueChange)="getValueChange($event)"
  (dateChange)="getDateChange($event)"
  (ready)="getReady($event)"
></app-time-range-slider>

<app-image-columns
  [data]="imageArray"
  [column]="1"
></app-image-columns>
