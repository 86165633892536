import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ApiGateway} from "../../http/api-gateway";
import {FileService} from "../../http/file.service";

export interface ImageSchema {
  title: string
  src: string
  ref?: {
    filename: string
    path?: string
    params?: { [key: string]: string } | string
  }
}

@Component({
  selector: 'app-image-columns',
  templateUrl: './image-columns.component.html',
  styleUrls: ['./image-columns.component.css']
})
export class ImageColumnsComponent {

  _data: ImageSchema[];
  rowIndices: number[];
  columnIndices: number[][] = [];
  columnData: { style: string, info: ImageSchema[] }[];

  @Input()
  maxHeight: number = 0;

  @Input()
  hasDownload: boolean = false;

  @Input()
  column: 1 | 2 | 3 | 4 | 6 | 12 = 1;

  @Input()
  imageRatio: string = '0.72941176470588235294117647058824';

  @Input()
  set data(data: ImageSchema[]) {
    if (this.column == undefined || this.column == null)
      return;

    this.columnData = [];
    this.columnIndices = [];

    this._data = data;
    this.rowIndices = this.getSubColunmsIndices();

    this.rowIndices.map(rowIndex => {
      let col = this.getSubColumns(rowIndex);

      this.columnData.push({
        style: this.getColumnStyle(rowIndex),
        info: col
      })

      this.columnIndices.push([...Array(col.length).keys()]);
    });

  }

  @Input()
  restPolicy: 'division' | 'full' = 'division';

  constructor(private api: FileService) { }

  // getHeight(this)
  getHeight(event) {
    const width = event.target.offsetWidth;
    return width * parseFloat(this.imageRatio);
  }

  getWidth(event) {
    const height = event.target.clientHeight;
    return height / parseFloat(this.imageRatio);
  }

  getColumnStyle(subColumnsIndex: number) {
    const subColumns = this.getSubColumns(subColumnsIndex);
    const columnCount = this.column as number;

    if (subColumns.length >= columnCount) {
      return `is-${12 / columnCount}`;
    }
    else {
      return this.restPolicy == 'full' ? 'is-12' : 'is-'+ (12 / this.column);
    }
  }

  getSubColunmsIndices() {
    if (this._data == null || this._data.length <= 0)
      return [];

    return [...Array(Math.ceil(this._data.length / this.column)).keys()];
  }

  getSubColumns(subColumnsIndex: number) {
    const startIndex = subColumnsIndex * (this.column as number);
    let endIndex = startIndex + (this.column as number);
    if (endIndex >= this._data.length) {
      endIndex = this._data.length;
    }

    return this._data.slice(startIndex, endIndex);
  }

  getImageId(index: number, colIndex: number) {
    return `img_${index}_${colIndex}`;
  }

  goFullScreen($event, i, colIndex) {
    console.log(this.getImageId(i, colIndex))
    let img = document.getElementById(this.getImageId(i, colIndex)) as HTMLImageElement;
    if (img.requestFullscreen) {
      img.requestFullscreen();
    }
  }

  downloadReference($event: MouseEvent, imageSchema: ImageSchema) {
    $event.stopPropagation();
    if (!imageSchema?.ref || !imageSchema.ref.filename || !imageSchema.ref.path || !imageSchema.ref.params)
      return;
    console.log(imageSchema.ref)
    const ref = imageSchema.ref;
    this.api.downloadFile(ref.path, ref.filename, ref.params)
  }
}
