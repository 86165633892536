<app-rich-text-viewer
  [type]="data?.type"
  [title]="data?.title"
  [editor]="data?.editor"
  [content]="data?.content"
  [view]="data?.viewCount"
  [createdAt]="data?.createdAt"
  [updatedAt]="data?.updatedAt"
  [files]="data?.files"
  (edit)="navigateToEditPage($event)"
  (delete)="requestDeletePost($event)"
  (download)="downloadFile($event)"
></app-rich-text-viewer>
