import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiGateway} from "../http/api-gateway";
import {BehaviorSubject, Observable} from "rxjs";

type QueryParams = {
  flightName?: string;
  date: string;
}

export type FlightInfo = {
  flightName: string;
  baseTime: string
  latitude: number;
  longitude: number;
  region: string;
  landmark: boolean; // marker point
  altitude?: number;
  timeInUtc?: string;
  timeInLocal?: string;
  isLandmark?: boolean;
  seq?: number;
}

export type Location = [number, number] | number[];
export type FlightLocations = { [key: string]: Location }

@Injectable({
  providedIn: 'root'
})
export class FlightInfoService extends ApiGateway {
  protected get apiUrl(): string {
    return "api/v1";
  }
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  updateFlightInfo(postId, fileId) {
    return this.post('flight-path/update', {postId, fileId});
  }
  getFlightInfo(query: QueryParams): Observable<FlightInfo[]> {
    return this.get('flight-path', query) as Observable<FlightInfo[]>;
  }

  flightSubscriptions: { [key: string] : BehaviorSubject<Location> } = {};
  putSubject(flightName: string): BehaviorSubject<Location> {
    if (!this.flightSubscriptions[flightName]) {
      this.flightSubscriptions[flightName] = new BehaviorSubject<Location>([0, 0]);
    }
    return this.flightSubscriptions[flightName];
  }
  updateFlightLocation(flightName: string, location: Location | number[]) {
    if (this.flightSubscriptions[flightName]) {
      this.flightSubscriptions[flightName].next(location)
      console.log('update', flightName, location)
    }
  }
  deleteAllFlightInfo() {
    return this.post('flight-path/delete', {});
  }
  getFlightLocation(flightName: string): BehaviorSubject<Location> {
    return this.flightSubscriptions[flightName];
  }
}
