<main #main style=" background-image: url('assets/images/home/img_1.png');">
  <!-- Hero section -->
<!--  <section #hero id="hero" class="hero parallax is-large is-info">-->
<!--    <div class="hero-body is-flex is-flex-direction-column is-align-items-center is-justify-content-center ">-->
<!--      <div class="has-text-centered mb-5">-->
<!--        <p class="is-family-primary has-text-weight-bold is-size-2">ASIA-AQ</p>-->
<!--        <p class="is-family-primary has-text-weight-bold is-size-3">Campaign</p>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="buttons has-text-centered is-invisible-desktop is-invisible-tablet">&ndash;&gt;-->
<!--&lt;!&ndash;        <button class="button is-5 is-rounded is-info">&ndash;&gt;-->
<!--&lt;!&ndash;          <a (click)="scrollToElement('about')">소개</a>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--&lt;!&ndash;        <button class="button is-5 is-rounded is-info">&ndash;&gt;-->
<!--&lt;!&ndash;          <a (click)="scrollToElement('flight-info-section')">운행 정보</a>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--&lt;!&ndash;        <button class="button is-5 is-rounded is-info">&ndash;&gt;-->
<!--&lt;!&ndash;          <a (click)="scrollToElement('flight-path-map')">운행 경로</a>&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div class="has-text-centered-mobile" style="margin: 0 3rem">-->
<!--        <p>ASIA-AQ 캠페인은 아시아 지역의 대기질을 조사하는 국제 협력 프로젝트입니다.</p>-->
<!--      </div>-->
<!--      <div class="my-5"></div>-->
<!--    </div>-->
<!--  </section>-->

<!--  <div class="page-indicator is-flex is-flex-direction-column">-->
<!--    <i class="fas fa-circle fa-xs"></i>-->
<!--    <i class="fas fa-circle fa-xs"></i>-->
<!--    <i class="fas fa-circle fa-xs"></i>-->
<!--    <i class="fas fa-circle fa-xs"></i>-->
<!--  </div>-->s

  <!-- ASIA-AQ description -->
  <section #about id="about" class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center fade-in-box-1"
           [ngClass]="window?.innerWidth < 768 ? '' : 'pt-0'">
    <div class="about-container columns is-fullheight">
      <div class="column is-5-desktop is-12-mobile">
        <div class="is-flex is-full is-flex-direction-column is-justify-content-center is-align-items-center">
          <img class="asia-aq-logo image is-align-items-center" src="./assets/images/ASIA-AQ.png" alt="">
        </div>
      </div>
      <div class="column is-flex is-align-items-center mb-3">
        <div class="is-family-primary vertical-line-on-left my-3">
          <h4 class="title is-3 has-text-centered-mobile">ASIA-AQ 캠페인</h4>
          <div class="subtitle has-text-centered-mobile is-size-6 mb-3">
            <p><u>A</u>irborne and <u>S</u>atellite <u>I</u>nvestigation of <u>A</u>sian <u>A</u>ir <u>Q</u>uality</p>
          </div>
          <br>
          <div class="is-text has-text-centered-mobile is-size-6 pb-0">
            <strong>아시아 대기질 항공 및 위성 조사</strong>
            <p class="p-0" style="font-size: 0.9rem;">
              ASIA-AQ 캠페인은 아시아 지역의 여러 국가들에서 다각적인 관측과 모델링을 통해 각국 대기질
              문제에 대한 구체적인 이해를 향상시킬 수 있는 국제 협력의 기회를 제공합니다.<br>
              대한민국, 미국, 필리핀, 말레이시아, 태국 등 각국의 과학자, 관련 기관, 정부 파트너와 협력하여
              지상, 항공, 위성 등 다각적인 관측을 수행합니다.<br>
              <br>
              실시간 관측 비행기 위치:<br>
              <a href="https://airbornescience.nasa.gov/tracker/#page=map&zoom=8&tail=N817NA" style="text-decoration: underline;">
                <strong>🔗 NASA DC-8 위치 보기</strong>
              </a>
              <br>
              <br>
              캠페인 기간:<br>2024.2.17(토) - 3.11(월)<br>
              <br>
              주관 기관:<br>국립환경과학원, 미국항공우주국(NASA)
            </p>
          </div>
          <br>
        </div>
      </div>
    </div>
  </section>

<!--    <div class="has-text-centered">-->
<!--      <div class="has-text-weight-light is-size-7 is-flex">-->
<!--        <br>-->
<!--        아래로 스크롤 해주세요-->
<!--      </div>-->
<!--    </div>-->

<!--  &lt;!&ndash; ASIA-AQ detail description &ndash;&gt;-->
<!--  <section class="is-flex fade-in-box-1 is-justify-content-center" style="background-color: white">-->
<!--    <div class="container columns is-flex-direction-row">-->
<!--      <div class="column is-5-desktop is-12-mobile is-flex is-flex-direction-column is-justify-content-center">-->
<!--        <img class="asia-aq-logo image is-fullwidth is-centered" src="https://espo.nasa.gov/sites/default/files/styles/625-wide/public/asset_images/ASIA-AQ_Earth.png?itok=vAlA-O5q">-->
<!--      </div>-->
<!--      <div style="height: 40px"></div>-->
<!--      <div class="column is-flex is-align-items-center">-->
<!--        <div class="subtitle">-->
<!--          <div>-->
<!--            <div class="is-family-primary vertical-line-on-left">-->
<!--              <strong>목적:</strong> 다양한 관측과 모델링을 통해 아시아 지역의 대기질을 제어하는 요소에 대해 이해합니다.-->
<!--              <br><br>-->
<!--              <strong>방식:</strong> 현지 과학자, 대기질 관련 기관 및 기타 파트너와 협력하여 국가별 항공 샘플링을 수행합니다.-->
<!--              <br><br>-->
<!--              <strong>철학:</strong> 모든 단계에서 데이터를 공개하고, 현지 과학자 및 관련 기관과 공동 분석을 수행하며, 발견된 사항을 지역 정부에 보고합니다.-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->

  <!-- Flight path information -->

  <!-- <section *ngFor="let flight of flightRoutes; let i = index" class="padding-auto is-flex is-full p-1 is-flex-direction-column is-justify-content-start">/
    <div>
      <h4 class="title is-4 has-text-centered my-3">관측 항공기 정보</h4>
      <div class="info-container is-flex is-justify-content-center is-align-items-center"
           [style]="window?.innerWidth < 768 ? 'flex-direction: column' : 'flex-direction: row'">
        <div class="card-wrapper p-3" >
          <div class="card box `p-1" *ngFor="let summary of [getPlanSummary(flight)]">
            <h3 class="card-header-title is-flex-grow-1 is-flex is-justify-content-center is-flex-direction-column has-text-centered">
              <i class="fa fa-plane"></i>
              <span>{{summary.name}}</span>
            </h3>
            <hr class="card-header-divider m-0">
            <div class="card-content p-0" (drag)=stopBubble($event) (click)="stopBubble($event)">

              <div class="is-flex is-fullwidth card"
                   [style]="window?.innerWidth < 768 ? 'flex-direction: column' : 'flex-direction: column'">
                <div class="is-6 is-fullwidth">
                  <div class="field is-flex is-fullwidth is-align-items-center"
                       [style]="window?.innerWidth < 768 ? 'flex-direction: row' : 'flex-direction: column'">
                    <div class="control has-text-centered m-0 is-fullwidth">
                      <ng-container *ngIf="isDefaultFlight; then DefaultStart; else realStart"></ng-container>
                      <ng-template #DefaultStart>
                        <p class="px-3 is-size-5">❗ 오늘은 관측 비행이 없습니다.</p>
                      </ng-template>

                      <ng-template #realStart>
                        <ng-container *ngIf="allTimeIsNull; then atis else stis"></ng-container>
                        <ng-template #atis></ng-template>
                        <ng-template #stis>
                          <label class="label has-text-centered m-0">출발</label>
                          <p class="px-3">{{summary.start}}</p>
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div class="is-6 is-fullwidth">
                  <div class="field is-flex is-fullwidth"
                       [style]="window?.innerWidth < 768 ? 'flex-direction: row' : 'flex-direction: column'">
                    <div class="control has-text-centered m-0 is-fullwidth">
                      <ng-container *ngIf="isDefaultFlight; then DefaultEnd; else realEnd"></ng-container>
                      <ng-template #DefaultEnd>
                        <p class="px-3 other-info">
                          내일 비행 여부는 정오 이후에 확인할 수 있습니다.
                          <br>
                          아래는 일반적인 비행경로이며, 실제 경로는 비행 당일 사정에 따라 바뀔 수 있습니다.
                        </p>
                      </ng-template>

                      <ng-template #realEnd>
                        <ng-container *ngIf="allTimeIsNull; then atis else stis"></ng-container>
                        <ng-template #atis></ng-template>
                        <ng-template #stis>
                          <label class="label has-text-centered m-0">도착</label>
                          <p class="px-3">{{summary.end}}</p>
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <div [style]="window?.innerWidth < 768 ?  'margin: 0.5rem 1rem' : 'margin: 0.5rem 0 1rem '"></div>
              <div class="is-flex is-fullwidth" [style]="window?.innerWidth < 768 ? 'flex-direction: row' : 'flex-direction: column'">
                <div class="field is-flex is-fullwidth is-flex-direction-column box is-full px-3">
                  <div class="timeline">
                    <div *ngFor="let landmark of summary.landmarks" class="timeline-event">
                      <div class="timeline-container">
                        <div class="timeline-content">
                          <span>{{landmark.name}}</span>
                        </div>
                        <div class="timeline-point-track-container">
                          <div class="timeline-point"
                          [ngClass]="{'status-passed': landmark.status === 'passed',
                                      'status-current': landmark.status === 'current'}"></div>
                        </div>
                        <div class="timeline-date3">
                          <span class="timeline-date">{{landmark.time}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- Flight path map -->
  <!-- <section #flight_map id="flight-map">
    <div class="is-flex is-full p-1 is-flex-direction-column is-justify-content-center">
      <h4 class="title is-4 has-text-centered">관측 비행 경로</h4>
      <div class="padding-auto map-container">
        <div class="map-frame">
          <div id="map"></div>
        </div>
      </div>
    </div>
  </section>
  <div class="scroll-down has-text-white" [ngClass]="showArrow ? '' : 'is-hidden'">
    <i class="fas fa-chevron-down fa-2x"></i>
  </div> -->
</main>
