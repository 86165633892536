<app-check-remove-user-modal [(active)]="isShowModalForRemoveUser" (remove)="remove()"></app-check-remove-user-modal>

<app-collapsible-members-table [data]="data()" class="has-text-centered">
    <ng-template let-element #buttons>
        <div class="field has-addons">
            <p class="control mx-2">
                <button class="button is-small is-warning" (click)="edit($event, element)">
                    <span class="icon is-small">
                        <i class="fas fa-user-edit"></i>
                    </span>
                    <span>
                        Edit
                    </span>
                </button>
            </p>
            <p class="control mx-2" *ngIf="!isRootUser(element)">
                <button class="button is-small is-danger" (click)="showDialogForRemoveUser($event, element)">
                    <span class="icon is-small">
                        <i class="fas fa-user-slash"></i>
                    </span>
                    <span>
                        Remove
                    </span>
                </button>
            </p>
        </div>
    </ng-template>
</app-collapsible-members-table>
<div class="mt-3"
     style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; height: 100%;">
  <input type="text" class="input" placeholder="Search..." [(ngModel)]="_searchKey" (ngModelChange)="onChangeSearchBar($event)">
</div>
<div class="mt-3"
     style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; height: 100%;">
    <ngx-bulma-pagination [max]="max" (pageChange)="pageChange($event)"></ngx-bulma-pagination>
</div>

