<app-time-range-slider [unit]="'h'" [intervals]="[3]" [interval]="3" [step2]="6"
    [isHiddenSlider]="isSelectedCurtain()" (ready)="getReady($event)" (dateChange)="getDateChange($event)"
    (valueChange)="getValueChange($event)" [relativeMin]="24" [relativeMax]="75">
</app-time-range-slider>

<div class="columns is-vcentered is-mobile my-3">
    <div class="column">
        <div class="radio-field-container">
            <app-radio-field [values]="['spatial', 'vertical']" [subLabels]="['spatial', 'vertical']"
                [label]="'Kind'" [(form)]="form" [controlName]="'kind'" class="ml-5 mr-3"></app-radio-field>
        </div>
    </div>

    <div class="column" *ngIf="form.value.kind == 'spatial'">
        <div class="radio-field-container">
            <app-radio-field [values]="domain" [subLabels]="domain" [label]="'domain'" [(form)]="form"
                [controlName]="'domain'" class="ml-3 mr-3"></app-radio-field>
        </div>
    </div>

    <div class="column" *ngIf="!isSelectedVertical() && form.value.domain == domain[2]">
        <div class="radio-field-container">
            <app-radio-field [values]="areas" [subLabels]="areas" [label]="'area'" [(form)]="form"
                [controlName]="'area'" class="ml-3 mr-3"></app-radio-field>
        </div>
    </div>

    <div class="column" *ngIf="isSelectedVertical()">
        <div class="radio-field-container">
            <app-radio-field [values]="['CROSS01', 'CROSS02', 'CURTAIN01', 'CURTAIN02', 'CURTAIN03']"
                [subLabels]="['cross01', 'cross02', 'curtain01', 'curtain02', 'curtain03']"
                [label]="'cross_section'" [(form)]="form" [controlName]="'section'" class="ml-3 mr-3">
            </app-radio-field>
        </div>
    </div>

    <div class="column">
        <div class="radio-field-container">
            <app-radio-field [values]="['PM25', 'CO', 'NH3', 'O3', 'NO2', 'SO2']"
                [subLabels]="['PM2.5', 'CO', 'NH3', 'O3', 'NO2', 'SO2']" [label]="'variables'" [(form)]="form"
                [controlName]="'variable'" class=""></app-radio-field>
        </div>
    </div>
</div>
<app-image-columns [data]="makeImageData()" [column]="2"></app-image-columns>
    <!-- <app-image-table [headers]="data?.titles" [data]="getData()"></app-image-table> -->
