import { CustomStepDefinition, LabelType, Options } from '@angular-slider/ngx-slider';
import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as dateFns from "date-fns";
import * as _ from 'lodash';
import {TimeRangeSliderService} from './time-range-slider.service';

interface Indicies {
  start: number | (() => number)
  end: number | (() => number)
}

export interface DateRange {
  current: Date
  start: Date
  end: Date
}

@Component({
  selector: 'app-time-range-slider',
  templateUrl: './time-range-slider.component.html',
  styleUrls: ['./time-range-slider.component.scss']
})
export class TimeRangeSliderComponent implements OnInit, OnChanges {

  @Input() zoneOffset: number = 0;
  @Input() zone: string = 'KST';
  @Input() speed: number = 1;
  @Input() unit: 'd' | 'h' | 'm' = 'h';
  @Input() intervals: number[] = [1, 3];
  @Input() interval: number = 1; // multiplier of intervals with unit
  @Input() isShownInterval = true;
  @Input() step2: number = 6; // interval for displaying legend
  @Input() step2Interval: number = 1; // interval for displaying bars
  @Input() isPlaying: boolean = false;
  @Input() relativeMin = 0;
  @Input() relativeMax = 48;
  @Input() isDisabled = false;

  obsForm: FormGroup = {} as FormGroup;

  @Input()
  isHiddenSlider = false;

  _currentDate = new Date();
  get currentDate() {
    return this._currentDate;
  }
  @Input()
  set currentDate(date: Date) {
    switch (this.unit) {
      case 'd': this._currentDate = dateFns.startOfMonth(date); break;
      case 'h': this._currentDate = dateFns.startOfDay(date);   break;
      default:  this._currentDate = dateFns.startOfHour(date);  break;
    }
  }

  play = false;

  @Output() playChange = new EventEmitter<boolean>();
  @Output() ready = new EventEmitter<DateRange>();
  @Output() dateChange = new EventEmitter<DateRange>();
  @Output() valueChange = new EventEmitter<Date>();

  private isInit = false;
  private dateRange: Date[] = [];
  private timeout: any;

  // 구간 반복시 처음 위치를 기억하기 위해 필요
  private dateRangeStartIndex = 0;
  private dateRangeStartIndexCopy = 0;
  private dateRangeEndIndex = 0;

  minValue: number = 0;
  maxValue: number = 1;

  private dateInterval: DateRange = {
    current: this.currentDate,
    start: new Date(this.minValue),
    end: new Date(this.maxValue)
  }

  // options for ngx-slider
  options: Options = {
    disabled: this.isDisabled,
    showTicksValues: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.TickValue:
          let tickFormat;
          if (this.unit == 'd') {
            tickFormat = "dd";
          } else if (this.unit == 'h') {
            tickFormat = "HH";
          } else {
            tickFormat = "HH:mm";
          }
          return dateFns.format(value, tickFormat);
        default:
          let titleFormat;
          if (this.unit == 'd') {
            titleFormat = `yyyy.MM.dd '(${this.zone})'`;
          } else if (this.unit == 'h') {
            titleFormat = `yyy.MM.dd HH'h' '(${this.zone})'`
          } else {
            titleFormat = `yyy.MM.dd HH:mm '(${this.zone})'`;
          }
          if (this.zoneOffset !== 0) {
            return dateFns.format(dateFns.addHours(value, this.zoneOffset), titleFormat);
          }
          return dateFns.format(value, titleFormat);
      }
    }
  };

  constructor(private fb: FormBuilder, public service: TimeRangeSliderService) {
    this.obsForm = this.fb.group({
      speed: [1, Validators.required],
      interval: [1, Validators.required],
      intervals: [[1,3], Validators.required],
      step2: [6, Validators.required],
      step2Interval: [1, Validators.required],
      isPlaying: [false, Validators.required],
      relativeMin: [0, Validators.required],
      relativeMax: [48, Validators.required]
    });
  }

  /**
   * Input 변경 감지 후 구독되는 form에 push
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if ('speed' in changes) {
      this.speedFormValue = this.speed;
    }
    if ('interval' in changes) {
      this.intervalFormValue = this.interval;
    }
    if ('intervals' in changes) {
      this.obsForm.get("intervals")?.patchValue(this.intervals);
    }
    if ('step2' in changes) {
      this.obsForm.get("step2")?.patchValue(this.step2);
    }
    if ('step2Interval' in changes) {
      this.obsForm.get("step2Interval")?.patchValue(this.step2Interval);
    }
    if ('isPlaying' in changes) {
      this.obsForm.get("isPlaying")?.patchValue(this.play);
    }
    if ('relativeMin' in changes) {
      this.obsForm.get("relativeMin")?.patchValue(this.relativeMin);
    }
    if ('relativeMax' in changes) {
      this.obsForm.get("relativeMax")?.patchValue(this.relativeMax);
    }
  }

  ngOnInit(): void {
    this.changePlayStatus(false);
    this.resetSlider(this.currentDate, this.interval, this.indiciesForReset());
    this.options.tickValueStep = this.step2Interval;

    this.intervalFormValue = this.interval;
    // 구독 등록 및 변경 감지 후 실행 로직
    this.obsForm.get("isPlaying")?.valueChanges.subscribe(isPlaying => {
      this.changePlayStatus(isPlaying);
    });
    this.obsForm.get("speed")?.valueChanges.subscribe(speed => {
      this.speedFormValue = speed;
      this.changePlayStatus(false);
      this.resetSlider(this.currentDate, this.interval, this.currentIndicies());
    });
    // 변경 시 리셋하는 Input들
    const resetKeys = ["interval", "intervals", "step2", "step2Interval", "relativeMin", "relativeMax"];
    resetKeys.forEach(key => {
      this.obsForm.get(key)?.valueChanges.subscribe(() => {
        this.updateIndicesAndValues(this.indiciesForReset());
        this.changePlayStatus(false);
        this.resetSlider(this.currentDate, this.interval, this.currentIndicies());
      });
    });
  }



  /**
   * date를 기준으로 interval 간격으로 date range를 생성, min, max은 각각 최소, 최대 값
   * @param date
   * @param interval
   * @param min
   * @param max
   * @return Date[]
   */
  private createDateRange(date: Date, interval: number, min: number, max: number): Date[] {
    let dateAdder: any;
    if (this.unit == 'd') dateAdder = dateFns.addDays;
    if (this.unit == 'h') dateAdder = dateFns.addHours;
    if (this.unit == 'm') dateAdder = dateFns.addMinutes;
    let   minDate = dateAdder(date, min);
    const maxDate = dateAdder(date, max);
    const range = [];
    while (dateFns.isBefore(minDate, maxDate)) {
      range.push(minDate);
      minDate = dateAdder(minDate, interval);
    }
    return range;
  }

  private indiciesForReset(): Indicies {
    return {
      start: 0,
      end: () => this.dateRange.length - 1
    }
  }

  private currentIndicies(): Indicies {
    return {
      start: () => this.dateRangeStartIndex,
      end: () => this.dateRangeEndIndex
    }
  }

  private applyDateRangeToOptions() {
    const newOptions = _.cloneDeep(this.options);
    newOptions.tickStep = this.step2 / this.intervalFormValue;
    newOptions.stepsArray = this.dateRange.map((date: Date) => {
      const step: CustomStepDefinition = {value: date.getTime()};
      let isShownLegend;
      if (this.unit == 'd') {isShownLegend = date.getDate() == 1;}
      else isShownLegend = date.getHours() == 0;
      // if (isShownLegend) step.legend = dateFns.format(date, "MM/dd");
      return step;
    });
    this.options = newOptions;
  }

  private updateIndicesAndValues({ start, end }: Indicies) {
    const startIndex = start instanceof Function ? start() : start;
    const endIndex = end instanceof Function ? end() : end;

    this.changeMinValue(startIndex);
    this.changeMaxValue(endIndex);

    const startDate = new Date(this.minValue);
    const endDate = new Date(this.maxValue);

    this.dateInterval = {
      current: this.currentDate,
      start: startDate,
      end: endDate
    }
  }

  private dispatchForValueChanged() {
    this.updateIndicesAndValues(this.currentIndicies());
    this.valueChange.emit(this.dateInterval.start);
  }
  private dispatchForDateChanged() {
    this.updateIndicesAndValues(this.currentIndicies());
    this.dateChange.emit(this.dateInterval);
  }

  onChangeDate(date: Date) {
    if (this.currentDate == date) return;
    this.currentDate = date;
    this.resetSlider(this.currentDate, this.intervalFormValue, this.currentIndicies());
    if (!this.isInit) {
      this.isInit = true;
      this.ready.emit(this.dateInterval);
      return;
    }
    this.dispatchForDateChanged();
  }

  onChangeSliderValue(e: any) {
    this.dateRangeStartIndex = this.dateRange.findIndex(d => d.getTime() == e.value);
    this.dateRangeStartIndexCopy = this.dateRangeStartIndex;
    this.dateRangeEndIndex = this.dateRange.findIndex(d => d.getTime() == e.highValue);

    this.dispatchForValueChanged();
  }

  private changeMinValue(index: number) {
    this.dateRangeStartIndex = index;
    this.minValue = (this.dateRange[index] || this.dateRange[0]).getTime()
  }

  private changeMaxValue(index: number) {
    this.dateRangeEndIndex = index;
    this.maxValue = (this.dateRange[index] || this.dateRange[this.dateRange.length - 1]).getTime()
  }

  private resetSlider(date: Date, interval: number, indicies: Indicies) {
    this.currentDate = date;
    this.dateRange = this.createDateRange(date, interval, this.relativeMin, this.relativeMax);
    this.applyDateRangeToOptions();
    this.updateIndicesAndValues(indicies);
  }

  // play or pause
  private changePlayStatus(isPlayed: boolean) {
    if (this.play !== isPlayed) this.playChange.emit(isPlayed);
    this.play = isPlayed;
    if (this.timeout != null) clearInterval(this.timeout);
    if (!this.play) return;
    this.timeout = setInterval(() => {
      let nextStartIndex = this.dateRangeStartIndex + 1;
      this.dateRangeStartIndex = nextStartIndex <= this.dateRangeEndIndex
        ? nextStartIndex
        : this.dateRangeStartIndexCopy;
      this.dispatchForValueChanged();
    }, 1000 * this.speedFormValue);
  }

  private changeHours(action: string) {
    // const amount = parseInt(action.substr(0, 2), 10);
    const amount = parseInt(action, 10);
    const variation = (amount / this.intervalFormValue);

    let newIndex = this.dateRangeStartIndex + variation;
    const isUnderflow = newIndex < 0;
    const isOverflow = newIndex > this.dateRangeEndIndex;

    if (isUnderflow) {
      newIndex = this.dateRangeEndIndex + amount + 1;
    }
    else if (isOverflow) {
      newIndex = this.dateRangeStartIndex + variation - 1;
      if (newIndex >= this.dateRange.length - 1) {
        newIndex = 0;
      }
    }

    this.dateRangeStartIndex = newIndex;
    this.dateRangeStartIndexCopy = newIndex;
    this.updateIndicesAndValues(this.currentIndicies());
  }

  clickPlayControls(action: string) {
    if (action == "play" || action == "pause") {
      this.changePlayStatus(action === "play");
    }
    else {
      this.changePlayStatus(false);
      switch (action) {
        case "backward":
          this.changeMinValue(0);
          break;
        case "forward":
          this.changeMinValue(this.dateRangeEndIndex - 1);
          break;
        case "reset":
          this.updateIndicesAndValues(this.indiciesForReset());
          break;
        default:
          this.changeHours(action);
          break;
      }

      this.dispatchForValueChanged();
    }
  }

  get isAvailable() {
    return (this.dateRangeStartIndexCopy !== this.dateRangeEndIndex)
      && (this.isDisabled == false);
  }

  currentIntervalHour(isPositive: boolean) {
    return (isPositive ? "+" : "-") + this.intervalFormValue + this.unit;
  }

  get intervalFormValue(): number {
    return this.obsForm.get("interval")?.value;
  }

  set intervalFormValue(value: number) {
    if (this.intervalFormValue == value) return;
    this.obsForm.get("interval")?.patchValue(value);
  }

  get speedFormValue(): number {
    return this.obsForm.get("speed")?.value;
  }

  set speedFormValue(value: number) {
    if (this.speedFormValue == value) return;
    this.obsForm.get("speed")?.patchValue(value);
  }

  @HostListener('document:keydown',['$event'])
  onKeydown(event: KeyboardEvent) {
    console.log(event.code)
    if (event.code === 'Space') {
      event.preventDefault();
      if (this.play) {
        this.clickPlayControls('pause')
      } else {
        this.clickPlayControls('play')
      }
    }
    if (event.code === 'ArrowLeft') {
      if (this.dateRangeStartIndex > 0) {
        this.changeMinValue(this.dateRangeStartIndex - 1)
        this.dispatchForValueChanged();
      }
    }
    if (event.code ===  'ArrowRight') {
      if (this.dateRangeEndIndex > this.dateRangeStartIndex) {
        this.changeMinValue(this.dateRangeStartIndex + 1)
        this.dispatchForValueChanged();
      }
    }
  }
}
