<div class="columns m-0" style="width:100%; height: 100%">
    <nav class="navbar">
      <div class="box p-0 m-0" [ngClass]="{'is-collapsed': !isExpanded}">
        <div style="display: flex; justify-content: end">
          <a class="button collapse-button is-medium" aria-label="menu" aria-expanded="false"
           (click)="handleOnClickExpansion()">
            <span *ngIf="isExpanded">
              <i class="fas fa-bars"></i>
            </span>
            <span *ngIf="!isExpanded">
              <i class="fas fa-bars"></i>
            </span>
          </a>
        </div>
        <div class="hero is-info is-small pl-4 pr-6 py-4" *ngIf="!isExpanded">
          <p class="is-size-6"></p>
        </div>
        <div class="hero is-info is-small pl-4 pr-6 py-4" *ngIf="isExpanded">
          <p class="is-size-6">{{ adjusetdRootUrl | uppercase }}</p>
        </div>
        <div class="menu-list navbar-menu" *ngIf="isExpanded">
          <ul id="sidebarBasic">
            <li *ngFor="let menu of menus" class="navbar-item">
              <a [routerLink]="[ rootUrl, menu.path ]" routerLinkActive="active-menu">
                {{ menu?.title || adjustUrl(menu.path) }}
              </a>
              <ul style="width: 100%" *ngIf="menu?.children?.length > 0">
                <li class="navbar-item" *ngFor="let submenu of menu.children; let i = index">
                  <a *ngIf="menu.children[i] !== undefined" [routerLink]="[ rootUrl, menu.path, submenu.path ]" routerLinkActive="active-menu">
                    {{ submenu?.title || adjustUrl(submenu.path) }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <section style="width: 100%; height:100%;">
      <div class="px-5 pt-5 container">
        <div id="title" class="pb-5 has-text-centered-mobile">
          <span class="has-text-weight-bold is-size-3 is-block-mobile"> {{ subTitle1 | uppercase }} </span>
          <span class="has-text-weight-bold is-size-5 ml-1"> {{ subTitle2 | uppercase }} </span>
        </div>
        <router-outlet></router-outlet>
      </div>
    </section>
</div>

