import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as dateFns from 'date-fns';
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import {ImageSchema} from "../../../../../../common/image-columns/image-columns.component";

@Component({
  selector: 'app-gems',
  templateUrl: './gems.component.html',
  styleUrls: ['./gems.component.css']
})
export class GemsComponent implements AfterViewInit {

  currentDate = dateFns.set(new Date(), {
    hours: 9,
    minutes: 45,
    seconds: 0,
    milliseconds: 0
  });
  sliderDate = dateFns.set(new Date(), {
    hours: 9,
    minutes: 45,
    seconds: 0,
    milliseconds: 0
  });

  tabNames = [ 'AOD-443nm', 'NO2', 'O3T', 'HCHO' ];
  regions = ['EAST-ASIA', 'GEMS-FULL', 'KOREA'];

  imageInfo = [
    {
      title: 'EAST-ASIA',
      key: 'EAST_ASIA',
      src: ''
    },
    {
      title: 'GEMS-FULL',
      key: 'GEMS_FULL',
      src: ''
    },
    {
      title: 'KOREA',
      key: 'KOREA',
      src: ''
    }
  ]
  imageSchema : ImageSchema[];

  private activatedPath;

  constructor(private route: ActivatedRoute, private changeRef: ChangeDetectorRef) {
    this.activatedPath = this.route.snapshot.url[0].path;
  }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  readyDate(dateRange: DateRange) {
    this.currentDate = dateRange.current;
    this.sliderDate = dateRange.start;
    this.generateImageSources();
  }

  changeCurrentDate(dateRange: DateRange) {
    this.currentDate = dateRange.current;
    this.sliderDate = dateRange.start;
    this.generateImageSources();
  }

  changeDateValue(date: Date) {
    this.sliderDate = date;
    this.generateImageSources();
  }

  private generateImageSources() {
    const product = this.tabNames.find(t => t.toLowerCase() == this.activatedPath).replace('-' , '_');

    const formattedDate = dateFns.format(this.sliderDate, "yyyyMMddHH") + '45';
    this.imageSchema = this.imageInfo.map(schema => {
      const dirs = ['image', dateFns.format(this.currentDate, "yyyy/MM/dd"), 'satellite', 'gems', product.toLowerCase()].join('/');
      const fileName = [product, formattedDate, schema.key].join('_') + '.png';
      return {
        title: schema.title,
        src: dirs + '/' + fileName
      }
    });
  }
}
