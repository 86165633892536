import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as dateFnsFp from "date-fns/fp";
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import * as _ from "lodash";
import {Subject} from "rxjs";

type Kind = 'spatial' | 'flight' | 'tagged' | 'ground';
type Domain = 'SEAsia' | 'Philippines' | 'Malaysia' | 'Thailand'

type SliderOptions = {
  [key in Kind]?: {
    label: string,
    children: {
      [key in Domain]?: {
        label: string,
        interval: number,
        speed: number,
        disableSlider?: boolean,
        variables: string[],
      }
    }
  }
}

@Component({
  selector: 'app-ensemble-south-east-asia',
  templateUrl: './south-east-asia.component.html',
  styleUrls: ['./south-east-asia.component.css']
})
export class SouthEastAsiaComponent implements OnInit, AfterViewInit {

  form: FormGroup;

  sliderOptions: SliderOptions = {
    'spatial': {
      label: 'Spatial',
      children: {
        'Malaysia': {
          label: 'Malaysia',
          interval: 1,
          speed: 0.66666,
          variables: ['ALL'],
        },
        'Philippines': {
          label: 'Philippines',
          interval: 1,
          speed: 0.66666,
          variables: ['ALL']
        },
        'Thailand': {
          label: 'Thailand',
          interval: 1,
          speed: 0.66666,
          variables: ['ALL']
        },
        'SEAsia': {
          label: 'SEAsia',
          interval: 1,
          speed: 0.66666,
          variables: ['ALL']
        }
      }
    }
  }

  get kind() {
    return this.form.value.kind;
  }
  getKinds() {
    return Object.keys(this.sliderOptions);
  }
  get kindLabels() {
    return Object.keys(this.sliderOptions).map((key) => (this.sliderOptions[key].label || key));
  }
  get domain() {
    return this.form.value.domain;
  }
  getDomains(kind) {
    return Object.keys(this.sliderOptions[kind].children)
  }
  get domainLabels() {
    return Object.keys(this.sliderOptions[this.kind].children).map(
      (key) => (this.sliderOptions[this.kind].children[key].label || key)
    );
  }
  get variable() {
    return this.form.value.variable;
  }
  getVariables(kind, domain) {
    return this.sliderOptions[kind].children[domain]?.variables || [];
  }
  get variableLabels() {
    return this.sliderOptions[this.kind].children[this.domain]?.variables || [];
  }
  getInterval(kind, domain) {
    return this.sliderOptions[kind].children[domain]?.interval || 1;
  }
  kinds = [];
  domains = [];
  variables = [];
  currentDate: Date = new Date();
  sliderDate: Date = new Date();
  sliderInterval: number;
  sliderSpeed: number = 1;
  column: number = 1;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public validator: ValidatorService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.changeRef.markForCheck();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      kind: ['spatial', [Validators.required]],
      domain: ['SEAsia', [Validators.required]], // 'Malaysia', 'Philippines', 'Thailand', 'SEAsia
      variable: ['ALL', [Validators.required]],
      interval: [1, [Validators.required]],
      speed: [1, [Validators.required]],
    });
    this.subscribeChanges();
    this.kinds = this.getKinds();
    this.domains = this.getDomains(this.kinds[0]);
    this.variables = this.getVariables(this.kinds[0], this.domains[0]);
    this.sliderInterval = this.getInterval(this.kinds[0], this.domains[0]);
    this.sliderSpeed = this.sliderOptions[this.kinds[0]].children[this.domains[0]]?.speed || 1;
  }

  subscribeChanges() {
    this.form.get('interval').valueChanges.subscribe((interval) => {
      this.sliderInterval = interval;
    })
    this.form.get('domain').valueChanges.subscribe((domain) => {
      this.sliderInterval = this.getInterval(this.kind, domain);
      this.form.get('interval').setValue(this.getInterval(this.kind, domain));
      this.variables = this.getVariables(this.kind, domain);
      this.form.get('variable').setValue(this.getVariables(this.kind, domain)[0]);
      this.sliderSpeed = this.sliderOptions[this.kind].children[domain]?.speed || 1;
      this.form.get('speed').setValue(this.sliderOptions[this.kind].children[domain]?.speed || 1);
    });
    this.form.get('kind').valueChanges.subscribe((kind) => {
      this.form.get('domain').setValue(this.getDomains(kind)[0]);
      this.domains = this.getDomains(kind);
    });
  }

  handleOnReady(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
  }

  handleDateChange(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
  }

  handleValueChange(date: Date) {
    this.sliderDate = date;
  }

  get columnCount() {
    return 1
  }

  makeImageData(columnCount) {
    let imageArray = [];

    let type = 'sea'
    let kind = this.form.value.kind;
    let domain = this.form.value.domain;
    let variable = this.form.value.variable;
    const modelDate = this.currentDate;
    const forecastDate = dateFnsFp.addDays(1)(modelDate);

    let filenamePattern = '{type}_{kind}_{domain}_{variable}_{date}_{offset}.png'
    if (kind === 'ground') {
      filenamePattern = '{type}_{kind}_{domain}_{variable}_{date}00.png'
    }
    for (let i = 0; i < columnCount; i++) {
      const dir = [
        "/image",
        modelDate.getFullYear(),
        String(modelDate.getMonth() + 1).padStart(2, '0'),
        String(modelDate.getDate()).padStart(2, '0'),
        "modeling",
        type.toLowerCase(),
        kind.toLowerCase(),
        domain.toLowerCase(),
        variable?.toLowerCase()
      ].join("/")
      const filename = filenamePattern
        .replace('{type}', _.capitalize(type))
        .replace('{kind}', kind.toLowerCase())
        .replace('{domain}', domain.toLowerCase())
        .replace('{variable}', variable?.toUpperCase())
        .replace('{date}', dateFnsFp.format("yyyyMMdd")(forecastDate))
        .replace('{offset}', String(dateFnsFp.differenceInHours(forecastDate)(this.sliderDate)).padStart(3, '0'))
      imageArray.push({
        title: 'GEOS-Chem',
        src: dir + "/" + filename
      });
    }
    return imageArray;
  }
}
