<app-date-controls [date]="currentDate" (dateChange)="onDateChanged($event)"></app-date-controls>

<hr>

<div class="card my-3">
      <header class="card-header hero is-info is-bold">
        <p class="card-header-title has-text-white">
            GK2A AMI RGB + Aircraft path
        </p>
    </header>
    <div class="card-image has-text-centered">
        <div class="columns is-multiline">
            <div class="column" *ngFor="let src of sources">
                <figure class="mx-3 my-3">
                    <img [src]="src" />
                </figure>
            </div>
        </div>
    </div>
</div>
