<div class="hero is-info">
    <header class="hero-body">
        <p class="title is-size-1 is-vertical-middle">
            Schedule
        </p>
        <a href="https://calendar.google.com/calendar/u/0?cid=Y19mY2NnbW5xY3Y3Y2cxYWt1cDBtYWRqajFtMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t"
           target="_blank"
           class="button is-white card-header-icon"
          style="max-width: 200px">
            <i class="far fa-calendar-alt mr-2"></i>
            <span>Open calendar</span>
        </a>
    </header>

    <!-- <div class="card-content">
        <div class="content">
            <ul>
                <li *ngIf="events == null || events?.length == 0">
                    No events
                </li>
                <li *ngFor="let event of events">
                    <span class="tag is-warning mr-3">
                        <span>{{ event.start.dateTime.value | date:'yyyy.MM.dd HH:mm' }}</span>
                        <span class="mx-1"> - </span>
                        <span>{{ event.end.dateTime.value | date:'yyyy.MM.dd HH:mm' }}</span>
                    </span>
                    <span>{{ event.summary }}</span>
                </li>
            </ul>
        </div>
    </div> -->
</div>
