<!-- Main Logo -->
<section class="hero parallax is-large is-info" style="background-image: url('assets/images/home/img_1.png');">
    <div class="hero-body columns is-flex is-align-items-center is-flex-direction-column ">
      <div class="column has-text-centered is-flex is-justify-content-center is-flex-direction-column m-4 p-4" style="height: 100%;"
           [ngStyle]="window?.innerWidth < 768 ? {'max-height': '55%'} : {'max-height': '300px'}"
           [ngClass]="window?.innerWidth < 768 ? 'is-justify-content-end' : 'is-justify-content-center'"
      >
          <p class="is-family-primary has-text-weight-bold is-size-1">ASIA-AQ / SIJAQ</p>
          <p class="is-family-primary has-text-weight-bold is-size-3">Campaign</p>
        </div>
      <!--  공지  -->
<!--      <div class="box notification padding-auto p-1 m-4" style=" background-color:rgba(255,255,255,0.11); max-height: 150px; overflow-y: scroll;">-->
<!--        <div class="notification-item">-->
<!--          <span class="m-2"></span>-->
<!--          게시판에 검색 기능이 추가되었습니다.-->
<!--        </div>-->
<!--        <div class="notification-item">-->
<!--          <span class="m-2"></span>-->
<!--          <p>-->
<!--            Field Campaign Data Collected에 icartt 파일 차트 표출 기능이 추가되었습니다.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
    </div>
</section>
