<app-rich-text-editor
  [mode]="editorMode"
  [title]="data?.title"
  [content]="data?.content"
  [type]="data?.type"
  [uploadedFiles]="data?.files"
  (uploadedFilesChange)="onChangeUploadedFiles($event)"
  [fileType]="targetFileType"
  (save)="savePostToServer($event)"
></app-rich-text-editor>
