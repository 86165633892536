import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['/forecast.component.css']
})
export class ForecastComponent {

  private activatedPath;

  constructor(private route: ActivatedRoute, private changeRef: ChangeDetectorRef) {
    this.activatedPath = this.route.snapshot.url[0].path;
  }

  tabNames = ['current', 'forecast'];
}
