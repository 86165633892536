import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/common/table/table.module';
import { RouterModule } from '@angular/router';
import { ResourcesTableComponent } from './resources-table.component';
import { CustomPipeModule } from '../../pipe/custom-pipe.module';
import {HighchartsModule} from "../../highcharts/highcharts.module";
import {SelectModule} from "../../select/select.module";
import {CheckboxButtonsModule} from "../../buttons/checkbox-buttons/checkbox-buttons.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [ResourcesTableComponent],
    imports: [
        CommonModule,
        TableModule,
        RouterModule,
        CustomPipeModule,
        HighchartsModule,
        SelectModule,
        CheckboxButtonsModule,
        FormsModule,
    ],
  exports: [ResourcesTableComponent]
})
export class ResourcesTableModule { }
