import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiGateway } from './api-gateway';
import jwt_decode from "jwt-decode";
import { ProgressService } from '../common/progress/progress.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiGateway {

  decoded:any;
  users:any;
  one:any = null

  constructor(
    protected http: HttpClient, private progress : ProgressService, private router: Router) {
    super(http);
  }

  protected get apiUrl(): string {
    return "user";
  }

  login(value): Observable<any> {
    return this.post("login", value).pipe(
      tap((res:any) =>
        sessionStorage.setItem("token", res["token"])
      ));
  }

  // check if ID == 'root@root.com'
  isAdmin() {
    return this.who() === "root@root.com"
  }

  logout() {
    sessionStorage.removeItem("token");
  }

  join(value) {
    return this.post("join", value);
  }

  update(value){
    return this.put("update", value);
  }

  remove(id){
    this.delete("delete/"+id).subscribe(_response => {
      if(_response) window.location.reload();
    },(error:HttpErrorResponse) => {
      alert(error);
    });
  }

  list(value){
    return this.post("list", value).pipe(
      tap(res => this.users = res as [])
    );
  }

  user(value){
    return this.post("one", value);
  }

  // return : user list
  noauthList(value) {
    return this.post("noauth/list", value);
  }

  validEmail(value){
    return this.post("noauth/email", value);
  }

  permit(value){
    this.progress.show("watingpermit", {
      errorLimitSec: 10
    });

    return this.put("permit", value).subscribe(_response => {
      this.router.navigate(["admin", "members", "list", "permitted"]);
    },(error:HttpErrorResponse) => {
      alert(error);
      console.log(error);
    },() => {
      this.progress.hide();
    });
  }

  who() {
    if(this.jwt != null)
      return jwt_decode(this.jwt)["sub"];
  }

  get role() {
    if(this.jwt != null)
      return jwt_decode(this.jwt)["authorities"][0]["authority"]?.toUpperCase();
  }

  get jwt() {
    return sessionStorage.getItem("token");
  }

  hasStaffRole(): boolean {
    return this.role == "ROLE_STAFF";
  }

  hasAuthentication(): boolean {
    return this.jwt != null;
  }
}
