<div class="columns " *ngFor="let i of rowIndices">
    <div class="column"
         [ngClass]="columnData[i].style" *ngFor="let c of columnIndices[i]"
         [ngStyle]="maxHeight ? {'max-height': maxHeight} : {}"
    >
        <div class="card">
            <header class="card-header hero is-info is-bold">
              <p class="card-header-title has-text-white">
                {{ columnData[i]?.info[c].title }}
                <span class="empty-space"></span>
                <button *ngIf="hasDownload" id="refDown_btn" class="card-header-icon" (click)="downloadReference($event, columnData[i]?.info[c])">
                    <span class="icon">
                        <i class="fas fa-download"></i>
                    </span>
                </button>
                <button id="fullscreen_btn" class="card-header-icon" (click)="goFullScreen($event, i, c)">
                    <span class="icon">
                        <i class="fas fa-expand-arrows-alt"></i>
                    </span>
                </button>
              </p>
            </header>
            <div class="card-image has-text-centered">
                <figure class="mx-3 my-3 {{imageRatio}}" >
                    <img
                      [id]="getImageId(i, c)"
                      [src]="columnData[i]?.info[c].src"/>
                </figure>
            </div>
        </div>
    </div>
</div>

