import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class HighchartsService {

  constructor() { }

  charts = {}
  queue = []
  defaultOptions = {
    title: {
      text: 'Solar Employment Growth by Sector, 2010-2016'
    },
    subtitle: {
      text: 'Source: thesolarfoundation.com'
    },
    yAxis: {
      title: {
        text: 'Number of Employees'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    plotOptions: {
      series: {
        pointStart: 2010,
        point: {
          events: {
            click: function () {
              alert('Category: ' + this.category + ', value: ' + this.y);
            }
          }
        }
      }
    },
    series: [{
      name: 'Installation',
      data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
    }, {
      name: 'Manufacturing',
      data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
    }, {
      name: 'Sales & Distribution',
      data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
    }, {
      name: 'Project Development',
      data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
    }, {
      name: 'Other',
      data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
    }],
    chart: {
      events: {
        click: function (e) {
          alert('Chart container clicked at: ' + e.yAxis[0].value);
        }
      }
    }
  }

  createChart(containerEl, key: any, options) {
    let opts = !!options ? options : this.defaultOptions;
    let chartEl = document.createElement("div");
    containerEl.appendChild(chartEl);
    opts.chart.renderTo = chartEl;
    this.save(key, opts);
  }

  drawChartOn(containerEl, key: any, options?) {
    let opts = !!options ? options : this.defaultOptions;
    let e = document.createElement("div");
    containerEl.appendChild(e);
    opts.chart.renderTo = e;
  }

  save(key: any, options: any) {
    this.charts[key] = options;
    this.queue.push(key);
    if (this.queue.length > 3) {
      delete this.charts[this.queue.shift()];
    }
    return this.charts[key];
  }

  get(key: any) {
    return this.charts[key];
  }

  getChartInstances(key: any) {
    return this.charts[key].map(chart => chart.length);
  }

  getCharts() {
    return Object.keys(this.charts).map(key => this.charts[key]);
  }
}
