export type ModelInfo = {
  name: string;
  team: string;
}

export const MODELS_INFO: ModelInfo[] = [
  {"name": "GRIMs-CCM", "team": "SNU"},
  {"name": "CMAQ-AJOU", "team": "AJOU"},
  {"name": "CMAQ-GIST", "team": "GIST"},
  {"name": "CMAQ-UNIST", "team": "UNIST"},
  {"name": "WRF-GC", "team": "SNU"},
  {"name": "WRF-Chem", "team": "UNIST"},
  {"name": "ENSEMBLE", "team": ""}
]
