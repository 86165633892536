<div class="tabs is-toggle is-fullwidth is-small">
    <ul>
      <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabName of tabNames">
        <a [routerLink]="[ '/routine-data-collected', 'weather-analysis', (tabName | lowercase)]">
          <span>{{ tabName }}</span>
        </a>
      </li>
    </ul>
</div>
<app-weather-map></app-weather-map>
<app-satellite></app-satellite>
<app-rader></app-rader>
<app-sat-and-radar></app-sat-and-radar>
