<div class="box m-0">
  <div class="is-flex is-full is-justify-content-center mb-3">
    <div class="search-container is-flex is-justify-content-start" style="flex-grow: 1">
      <div class="select">
        <select [(ngModel)]="category"
                (ngModelChange)="onChangeCategory($event)"
                [value]="category">
          <option value="title">title</option>
          <option value="content">content</option>
        </select>
      </div>
      <div class="field">
        <p class="control has-icons-right">
          <input class="input" type="text" placeholder="Search"
                 (keydown.enter)="onEnterSearchBar($event)"
                 [(ngModel)]="searchKey"
                 (ngModelChange)="onChangeSearchBar($event)"
                 title="Press Enter to search"
          >
          <span class="icon is-normal is-right"
                (mouseover)="onMouseOverSearchButton($event)"
                (click)="onClickSearchButton($event)">
            <span class="is-info is-small"
                  title="Search"
            ><i class="fas fa-search"></i>
            </span>
          </span>
        </p>
      </div>
    </div>
    <a class="button is-right is-info"
       style="height: 36px"
       [routerLink]="[ 'new' ]"
       *ngIf="auth.hasAuthentication()">
      <span>Upload</span>
    </a>
  </div>

  <app-collapsible-table [data]="postData" [size]="pageSize" [headers]="tableHeaders" (open)="onClickPost($event)"
                         [expandedIndex]="getIndex(expandedPostId)">
    <ng-template let-element let-index="index" #row>
      <td class="col_index has-text-centered is-vertical-align">
        {{ element.id }}
      </td>
      <td class="col_type has-text-centered is-vertical-align" *ngIf="this.boardTypes != null">
        {{ getTypes(element.type) }}
      </td>
      <td class="col_title has-text-centered is-vertical-align">
      {{ element.title }}
      </td>
      <td class="has-text-centered">
        <div class="columns" *ngIf="element.files?.length > 0">
          <div class="column is-12 is-flex is-align-items-center is-justify-content-center">
          <span class="is-size-7 mx-1">
            <span> {{ getFileCount(element) }}</span>
            <span> ({{ getTotalFileSize(element) | fileSize }})</span>
          </span>
            <div class="select is-small">
              <select class="mx-1" style="max-width:10rem; text-overflow: ellipsis; white-space: nowrap;"
                      (click)="$event.stopPropagation()" (change)="onSelectFile($event, element.id)">
                <option value="-1" selected disabled hidden>Select file</option>
                <option value="-2">All</option>
                <option value="{{ file.id }}" *ngFor="let file of element.files">
                  {{ file.name }}
                </option>
              </select>
            </div>
            <a class="button is-small mx-1" title="Download"
               (click)="downloadFiles($event, element)">
              <i class="fas fa-download"></i>
            </a>
            <a class="button is-small mx-1" title="Show icartt" *ngIf="auth.hasAuthentication()"
               (click)="onToggleChart($event, element.id, selectedFileMap[element.id])">
              <i class="fas fa-chart-line"></i>
            </a>
          </div>
        </div>
      </td>
      <td class="col_editor has-text-centered is-vertical-align" *ngIf="auth.hasAuthentication()">
      {{ element.writer }}
      </td>
      <td class="col_date has-text-centered is-vertical-align">
      {{ element.createdAt | date: 'yyyy-MM-dd' :'+1800' }}
      </td>
      <td class="col_view has-text-centered is-vertical-align">
        {{ element.viewCount }}
      </td>

    </ng-template>

    <ng-template let-element let-i=index #expanded>

      <!-- Error Message -->
      <p *ngIf="getToggleErrorMessage(element.id) != null" class="column is-12 has-text-centered"
         [style]="getToggleErrorMessage(element.id) != null ? 'height: 2rem;' : 'height: 0px'">
        {{ getToggleErrorMessage(element.id) }}
      </p>

      <div class="is-fullwidth" *ngIf="shouldExpand(element.id) != null" style="position: relative; height: 0">
        <button (click)="onClickToggleOffButton($event, element.id)" style="position: absolute; top: 0; right: 0;">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <!-- Chart -->
      <ng-container *ngIf="shouldExpand(element.id) === 'chart'">
        <div class="is-flex is-flex-direction-column is-justify-content-center" style="width: 100%; height: 100%;">
          <app-highcharts
            [title]="expandableContentsMap[element.id].title"
            [data]="expandableContentsMap[element.id].chartData"
          ></app-highcharts>
        </div>
      </ng-container>

      <!-- Post Preview -->
      <ng-container *ngIf="shouldExpand(element.id) === 'post'">
        <div class="is-flex is-flex-direction-column is-justify-content-center" style="width: 100%; height: 100%;">
          test
          {{ expandableContentsMap[element.id].postContent }}
        </div>
      </ng-container>

    </ng-template>
  </app-collapsible-table
  >
  <div class="my-3 padding-auto container">
    <div class="is-10 mb-3">
      <ngx-bulma-pagination
        [max]="maxPage"
        [currentPage]=currentPage
        (pageChange)="onPageChange($event)"
      ></ngx-bulma-pagination>
    </div>
  </div>
</div>


