import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsService } from "./highcharts.service";

export interface Control<T>{
  name?: string
  value: T
  filter?: (value: any) => boolean
  checked: boolean
}
export type XAxisType = 'linear' | 'datetime' | 'logarithmic' | 'category'
export type DateFormat = 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD' | 'HH:mm:ss'
export type DateFormatsControl = { name: DateFormat, value: DateFormat | string, checked: boolean }
export type NumberFormat = 0 | 1 | 2
export interface ChartData {
  headers : string[],
  data : { [key: string]: any[] }
  metadata?: { [key: string]: string },
}
@Component({
  selector: 'app-highcharts',
  templateUrl: './highcharts.component.html',
  styleUrls: ['./highcharts.component.css']
})
export class HighchartsComponent implements OnInit {

  @ViewChild('chart') public chartEl: ElementRef
  chartType: any = 'spline'
  chartTypeControls: Control<string>[] = [
    { name: 'spline', value: 'spline', checked: true },
    { name: 'line', value: 'line', checked: false },
    { name: 'column', value: 'column', checked: false },
    { name: 'area', value: 'area', checked: false },
    { name: 'areaspline', value: 'areaspline', checked: false },
    // { name: 'arearange', value: 'arearange', checked: false },
    // { name: 'areasplinerange', value: 'areasplinerange', checked: false },
    { name: 'bar', value: 'bar', checked: false },
    // { name: 'boxplot', value: 'boxplot', checked: false },
    // { name: 'bubble', value: 'bubble', checked: false },
    // { name: 'columnrange', value: 'columnrange', checked: false },
    // { name: 'errorbar', value: 'errorbar', checked: false },
    // { name: 'funnel', value: 'funnel', checked: false },
    // { name: 'gauge', value: 'gauge', checked: false },
    // { name: 'heatmap', value: 'heatmap', checked: false },
    // { name: 'pie', value: 'pie', checked: false },
    // { name: 'polygon', value: 'polygon', checked: false },
    // { name: 'pyramid', value: 'pyramid', checked: false },
    { name: 'scatter', value: 'scatter', checked: false },
    // { name: 'solidgauge', value: 'solidgauge', checked: false },
    // { name: 'treemap', value: 'treemap', checked: false },
    // { name: 'waterfall', value: 'waterfall', checked: false },
  ]

  @Input()
  key: string;

  @Input()
  title: string

  xAxis: string
  xAxisHeaderIndex: number;
  xAxisTypeControls: Control<XAxisType>[] = [
    { name: 'linear', value: 'linear', checked: true },
    { name: 'datetime', value: 'datetime', checked: false },
    { name: 'logarithmic', value: 'logarithmic', checked: false },
    { name: 'category', value: 'category', checked: false },
  ]
  timezone: number = -9
  timezoneControls: Control<number>[] = [
    { name: 'Asia/Seoul', value: -9, checked: true },
    { name: 'UTC', value: 0, checked: false },
    { name: 'America/New_York', value: 5, checked: false },
  ];

  xAxisType: XAxisType = 'datetime'
  referenceTime?: number // epoch time
  xAxisSelectControls: Control<string>[] = []
  showTooltip: boolean = false

  _data: ChartData
  @Input()
  set data(data: ChartData) {
    if (!data) return
    this.isUpdating = true
    this._data = data
    this.xAxis = data.headers[0]
    this.xAxisHeaderIndex = 0
    this.xAxisSelectControls = this.getXAxisControls(data.headers)
    this.options = this.getHighchartOption(data)
    this.isUpdating = false
  }
  get data() {
    return this._data
  }

  _options: any
  set options(options: any) {
    this._options = options
  }
  get options() {
    return this._options
  }

  nullIndicators = ['-9999', 'null', 'None', 'none', 'NaN', 'nan', 'N/A', 'n/a', 'NA', 'na', '']

  onChangeChartType(type: string) {
    this.isUpdating = true
    this.chartType = type
    this.options = this.getHighchartOption(this.data, this.xAxis)
    // this.getChartJs(this.data, this.xAxis)
    this.isUpdating = false
  }

  onChangeShowTooltip(checked: boolean) {
    this.isUpdating = true
    this.showTooltip = checked
    this.options = this.getHighchartOption(this.data, this.xAxis)
    // this.getChartJs(this.data, this.xAxis)
    this.isUpdating = false
  }

  onChangeXAxisType($event: any) {
    this.isUpdating = true
    this.options = this.getHighchartOption(this.data, this.xAxis)
    // this.getChartJs(this.data, this.xAxis)
    this.isUpdating = false
  }

  onChangeXAxis(xAxis: string) {
    this.isUpdating = true
    this.xAxisHeaderIndex = this.data.headers.indexOf(xAxis)
    this.options = this.getHighchartOption(this.data, xAxis)
    // this.getChartJs(this.data, xAxis)
    this.isUpdating = false
  }

  onChangeTimezone(offset: number) {
    this.isUpdating = true
    this.timezone = offset
    this.options = this.getHighchartOption(this.data, this.xAxis)
    // this.getChartJs(this.data, this.xAxis)
    this.isUpdating = false
  }

  @Input() isUpdating: boolean = false

  constructor(private hcs: HighchartsService, private changeDetectorRef: ChangeDetectorRef) {}

  getXAxisControls(headers: string[]) : Control<string>[] {
    return headers.map((header, index) => ({
      name: header,
      value: header,
      checked: header === this.xAxis
    }))
  }

  checkNull(value: any) {
    if (value == "-9999") {
      return null
    }
    return value
  }

  formatValue(value: any, unit: string) {
    if (unit === 'seconds') { // seconds -> milliseconds
      return Number(this.referenceTime + Number(value)*1000)
    }
    return Number(value)
  }

  getHighchartOption(chartData: ChartData, xAxis: string = chartData.headers[0]){
    // 참조 시간 설정
    const referenceTime = chartData.metadata?.referenceTime + '000' || ''
    let date;
    if (referenceTime) {
      date = new Date(Number(referenceTime))
      console.log('date', date, referenceTime)
      this.referenceTime = date.getTime()
    }
    const filename = chartData.metadata?.filename || ''
    const units : string[] = chartData.metadata?.units.split(',') || []

    let unit = chartData.metadata?.units.split(',')[this.xAxisHeaderIndex] || ''
    if (unit === 'seconds') unit = ''

    // 시리즈 데이터 구성
    const series = chartData.headers.map((header, i) => ({
      name: header,
      // turboThreshold: 1000000,
      custom: {unit: units[i]},
      visible: i > 2,
      data: chartData.data[chartData.headers[i]].map((yValue, j) => {
        return [this.formatValue(chartData.data[xAxis][j], units[this.xAxisHeaderIndex]), yValue]
      })
    }))

    return {
      plotOptions: {
        area: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        arearange: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        areaspline: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        areasplinerange: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        bar: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        boxplot: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        bubble: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        column: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        columnrange: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        errorbar: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        funnel: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        gauge: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        heatmap: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        line: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        pie: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        polygon: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        pyramid: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        scatter: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        series: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        solidgauge: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        spline: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        treemap: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
        waterfall: { animation: false, enableMouseTracking: this.showTooltip, stickyTracking: true, shadow: false },
      },
      chart: {
        animation: false,
        allowMutatingData: true,
        type: this.chartType,
        zoomType: 'x',
        height: (12 / 16 * 100) + '%',
        reflow: false,
      },
      time: {
        timezoneOffset: this.timezone * 60,
        useUTC: false,
      },
      title: { text: this.title },
      ...(referenceTime ? { subtitle: { text: `Reference Time: ${date}` } } : {}),
      xAxis: {
        title: { text: `${xAxis} ${unit ? `(${unit})` : ''}` },
        type: this.xAxisType,
      },
      yAxis: {
        title: { text: 'Values' },
      },
      tooltip: {
        useHTML: true,
        headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
          `<td style="text-align: right"><b>{point.y}</b></td></tr>`,
        footerFormat: '</table>',
        valueSuffix: `<div> {point.custom.unit}</div>`,
        enabled: this.showTooltip,
        animation: this.showTooltip,
      },
      series: series,
    };
  }

  ngOnInit(): void {
    if (!this.data) {
      return
    }
    this.isUpdating = false
  }

  public readonly Highcharts = Highcharts;

}
